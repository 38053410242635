import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PeticionesService } from './Providers/peticiones.service';
import { NgFallimgModule } from 'ng-fallimg';
import { ModalBuscarClientePageModule } from './Dialogos/modal-buscar-cliente/modal-buscar-cliente.module';
import { ModalAddProductoPageModule } from './Dialogos/modal-add-producto/modal-add-producto.module';
import { ModalCreateNewClientePageModule } from './Dialogos/modal-buscar-cliente/modal-create-new-cliente/modal-create-new-cliente.module';
import { ModalCategoriaPageModule } from './Dialogos/modal-categoria/modal-categoria.module';
import { ModalDepartamentosPageModule } from './Dialogos/modal-departamentos/modal-departamentos.module';
import { CrearnuevaCategoriaPageModule } from './Dialogos/modal-categoria/crearnueva-categoria/crearnueva-categoria.module';
import { CrearnuevoDepartamentoPageModule } from './Dialogos/modal-departamentos/crearnuevo-departamento/crearnuevo-departamento.module';
import { ModalProveedorPageModule } from './Dialogos/modal-proveedor/modal-proveedor.module';
import { CrearnuevoProveedorPageModule } from './Dialogos/modal-proveedor/crearnuevo-proveedor/crearnuevo-proveedor.module';
import { ServiceAjaxService } from './Providers/service-ajax.service';
import { AdministracionPageModule } from './Pages/administracion/administracion.module';
import { ClientesPageModule } from './Pages/clientes/clientes.module';
import { ComponentsModule } from './components/components.module';
import { ProveedoresPageModule } from './Pages/proveedores/proveedores.module';
import { VentasAgendadasPageModule } from './Dialogos/ventas-agendadas/ventas-agendadas.module';
import { CompletandoPagoPageModule } from './Dialogos/completando-pago/completando-pago.module';
import { LocalstoragesService } from './Providers/localstorages.service';
import { ReimpresionesPageModule } from './Dialogos/reimpresiones/reimpresiones.module';


//PDF
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import { FacturacionesService } from './Providers/facturaciones.service';
import { ComprobantesService } from './Providers/comprobantes.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SincronizarRNCPageModule } from './Dialogos/sincronizar-rnc/sincronizar-rnc.module';
import { LoginServiceService } from './Providers/login-service.service';
import { ReportesSService } from './Providers/reportes.service';
import { EmpresaService } from './Providers/empresa.service';


PdfMakeWrapper.setFonts(pdfFonts);
//fin PDF



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    SincronizarRNCPageModule,
    ReimpresionesPageModule,
    CompletandoPagoPageModule,
    VentasAgendadasPageModule,
    ModalBuscarClientePageModule,
    ModalAddProductoPageModule,
    ModalCreateNewClientePageModule,
    ModalCategoriaPageModule,
    ModalDepartamentosPageModule,
    CrearnuevaCategoriaPageModule,
    CrearnuevoDepartamentoPageModule,
    ModalProveedorPageModule,
    CrearnuevoProveedorPageModule,
    NgFallimgModule.forRoot({default: '/assets/mis-iconos/reportes.png'}),
    HttpClientModule,
    BrowserModule, IonicModule.forRoot(), AppRoutingModule],

  providers: [
    EmpresaService,
    ReportesSService,
    LoginServiceService,
    InAppBrowser,
    ComprobantesService,
    FacturacionesService,
    LocalstoragesService,
    PeticionesService,
    ServiceAjaxService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
