import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalAddProductoPageRoutingModule } from './modal-add-producto-routing.module';

import { ModalAddProductoPage } from './modal-add-producto.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalAddProductoPageRoutingModule
  ],
  declarations: [ModalAddProductoPage]
})
export class ModalAddProductoPageModule {}
