import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { BackbuttonComponent } from './backbutton/backbutton.component';

/*
  Se deben: 
  (1-) declarar en declarations 
  (2-) exportar

  (3-) importar IonicModule ,  para que funcione todos los comando de ionic
  los componentes que vayamos agregando para que puedan ser visibles de todos lados.
  */

@NgModule({
  declarations: [
    HeaderComponent,
    BackbuttonComponent
  ],
  exports:[
    HeaderComponent,
    BackbuttonComponent
  ],

  imports: [
    CommonModule,
    IonicModule
  ]
})
export class ComponentsModule { }
