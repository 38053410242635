import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CrearnuevaCategoriaPage } from './crearnueva-categoria/crearnueva-categoria.page';
import Swal from'sweetalert2';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';

@Component({
  selector: 'app-modal-categoria',
  templateUrl: './modal-categoria.page.html',
  styleUrls: ['./modal-categoria.page.scss'],
})
export class ModalCategoriaPage implements OnInit {
  inputFiltrar:string;
  public listadoCategoria:any[];

  constructor(private modalController: ModalController, public alertController:AlertController,
     public peticiones: PeticionesService, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.GetCategoria_LocalStorage();
  }


  salir_sin_parametro(){
    this.modalController.dismiss();
    }

  salir_con_parametro(codigo:string, categoria:string){
    this.modalController.dismiss({
      codigo: codigo,
      categoria: categoria

    });
    }


    public FiltrarCategorias(){
      if(localStorage.getItem(this.myLocalStorage.listado_Categorias)){
        //console.log('Si existen clientes')

        var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Categorias);
        let listadoTem:any = JSON.parse(ListadoTemp_Storage);
        //console.log(listadoClientesTem) 

        let listadoFiltrado = listadoTem.filter(
        data => data.Codigo.includes (this.inputFiltrar.toUpperCase()) ||
        data.Categoria.includes (this.inputFiltrar.toUpperCase()));
        this.listadoCategoria = listadoFiltrado

      }else{
        console.log('No existen categoria')
      }
    }


    // GET CATEGORIA
    public GetCategoria_LocalStorage(){
      if(localStorage.getItem(this.myLocalStorage.listado_Categorias)){
        var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Categorias);
        this.listadoCategoria = JSON.parse(ListadoTemp_Storage);
      }
    }

    
    // CREANDO NUEVA CATEGORIA
    public async openNuevaCategoria(codigoCategoria:string, DigCategoria:string, editar:string){
  try {
        const modal = await this.modalController.create({
          component: CrearnuevaCategoriaPage,
          cssClass: 'my-custom-class',
          componentProps: {
            codigoCategoriaTraida: codigoCategoria,
            categoriaTraida: DigCategoria,
            permisoEditarCategoriaTraida:editar
          },
        });
        await modal.present();
        //para recibir datos
      try {
            const {data} = await modal.onDidDismiss()
            if(data){
              //  console.log('Direccion: '+data.direccion + '\n Codigo'+ data.codigo + '\n Negocio' +data.negocio)
               this.GetCategoria_LocalStorage();
          }else{
              // this.MostrarPedidosJson();
            }
        } catch (error) {
          console.log('error al leer datos no existe!')
        }
          
  } catch (error) {
        // this.peticiones.Dialogo_Error_Condiciones('No Tienes Un Pedido Seleccionado');
  }

    }

    // CONFIRMAR CATEGORIA
    public dialogo_confirmar_categoria(categoria:string){
      this.modalController.dismiss({
        categoria:categoria
      });
    }


    //ELIMINAR
    public async dialogo_Eliminar(codigo:string, categoria:string){

      const alertImagenProducto =  await  this.alertController.create({
  
        header:  'Seguro que desea eliminar este registro?',
        message: categoria+'',
        // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
        cssClass: 'my-custom-class',
  
      buttons: [
        {
          role:'destructive',
          text: 'CANCELAR',
          handler: (valor) => {
                  // OK


  
          }
        },
        {
          role:'aceptar',
          text: 'ELIMINAR',
          handler: (valor) => {
                  // OK
              this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Categorias, 'Codigo',codigo);
              this.Dialogo_Espere('Eliminando','Registro Eliminado');

          }
        }
      ]
        });
        alertImagenProducto.present()
      }
      // Espere 3 segundos ...
    public Dialogo_Espere(titulo_, mensajeDialogo){
      let timerInterval
      Swal.fire({
        title: titulo_,
        html: '',
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton:false,
        willOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(() => {
            const content = Swal.getContent()
            if (content) {
              const b = content.querySelector('b')
              if (b) {
              }
            }
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          //despues que finaliza
            this.peticiones.Dialogo_exitoso(mensajeDialogo);
            this.GetCategoria_LocalStorage();
        }
      })
    }


}
