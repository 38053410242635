import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor() { }


  public datosEmpresa:any = {
  nombre: 'SisBOA CXA',
  rnc:  '00000000001',
  direccion: 'C/ PRINCIPAL #44',
  telefono: '809-000-0000'
}




}
