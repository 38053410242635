import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';


import { AlertController, NavController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { ServiceAjaxService } from './service-ajax.service';
import * as $ from "jquery";
import * as moment from 'moment';
// import { FacturacionesService } from './facturaciones.service';


import { Canvas, Cell, Img, Line, PdfMakeWrapper, QR, SVG, Table, TextReference, Txt } from 'pdfmake-wrapper';
import { FacturacionesService } from './facturaciones.service';
import { ComprobantesService } from './comprobantes.service';


@Injectable({
  providedIn: 'root'
})
export class PeticionesService {

  pdf = new PdfMakeWrapper();

  insertar_factura_json:string
  get_noFactura_json: string;

  insertar_cabezal_venta_json:string;
  insertar_detalles_venta_json:string;

  get_all_ventas_json:string;
  get_all_detalles_ventas_json:string;


  get_all_ventas_por_fechas_json:string;
  

  listaProductoJson: string;
  listaClienteJson: string;
  listaCategoriaJson: string;
  listaDepartamentosJson: string;
  listaProveedoresJson: string;

  constructor(public http: HttpClient, public navCont: NavController, 
              public router: Router, public serviceAjax: ServiceAjaxService,
              public facturacion: FacturacionesService, public comprobantesS: ComprobantesService
              ) {
     
    this.insertar_factura_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Insertar_Factura.php';
    this.get_noFactura_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Get_NoFactura.php';
   
    this.insertar_cabezal_venta_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Insertar_Nueva_Venta.php';
    this.insertar_detalles_venta_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Insertar_Detalles_Venta.php';

    this.get_all_ventas_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Ventas/Get_Ventas.php';
    this.get_all_detalles_ventas_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Ventas/Get_Detalle_Ventas.php';
    
    this.get_all_ventas_por_fechas_json = 'https://sisboa.net/SisBOA-WEB/Factura_Venta/Ventas/Get_Ventas_Por_Fechas.php';
  
    https://sisboa.net/SisBOA-WEB/Factura_Venta/Ventas/Get_Ventas_Por_Fechas.php?fechaDesde=2021-03-01&fechaHasta=2021-06-17 03:31:06

    this.listaProductoJson = 'https://sisboa.net/SisBOA-WEB/Productos_/Get_Productos_Ionic.php';
    this.listaClienteJson = 'https://sisboa.net/SisBOA-WEB/Clientes_/Get_Clientes_Ionic.php';
    this.listaCategoriaJson = 'https://sisboa.net/SisBOA-WEB/Categorias/Get_Categorias_Ionic.php';
    this.listaDepartamentosJson = 'https://sisboa.net/SisBOA-WEB/Departamentos/Get_Departamentos_Ionic.php';
    this.listaProveedoresJson = 'https://sisboa.net/SisBOA-WEB/Proveedores/Get_Proveedores_Ionic.php';
  }



  public Insertar_NoFacturaJson() {
    return this.http.get(this.insertar_factura_json).toPromise();
  }  
  //GET No.Factura MYSql
  public Obtener_NoFacturaJson() {
    return this.http.get(this.get_noFactura_json).toPromise();
  }


  public Insert_Cabezal_Venta_Json(NoFactura:string, MetodoPago:string, TipoComprobante:string, CodigoCliente:string, Cliente:string, 
    DireccionCliente:string, TelefonoCliente:string, NoComprobante:string, Descuento:string, 
    Itbis:string, Total:string, NoConfirmacionTrjeta:string,atendidoPor:any, cambio:any, recibido:any, rncCliente:any ) {

    const formData = new FormData();
    
    //CABEZAL
    formData.append("NoFactura", NoFactura);
    formData.append("MetodoPago", MetodoPago);
    formData.append("TipoComprobante", TipoComprobante);
    formData.append("CodigoCliente", CodigoCliente);
    formData.append("Cliente", Cliente);
    formData.append("DireccionCliente", DireccionCliente);
    formData.append("TelefonoCliente", TelefonoCliente);
    formData.append("NoComprobante", NoComprobante);
    formData.append("Descuento", Descuento);
    formData.append("Itbis", Itbis);
    formData.append("Total", Total);
    formData.append("NoConfirmacionTrjeta", NoConfirmacionTrjeta);
    formData.append("atendidoPor", atendidoPor);
    formData.append("recibido", recibido);
    formData.append('rncCliente', rncCliente);
    formData.append("cambio", cambio);

    return this.http.post(this.insertar_cabezal_venta_json, formData).toPromise();
  }



  // INSERTAR DETALLES
  public Insertar_Detalles_Json(NoFactura:string,  fechaParaFactura:any, 
    tipoNcf:any, cliente:any, ncfFactura:any, itbis:any, total:any, le_Atendio:any,
     metodoPago:any, recibido:any, cambio:any, rncCliente:any ){

    if(localStorage.getItem('Detalle_ventaTemporar')){
      let Detalle_ventaTemp_Storage = localStorage.getItem('Detalle_ventaTemporar');
      let Detalle_ventaTemp_ = JSON.parse(Detalle_ventaTemp_Storage);

        // preparamos Json para mysql
        var Array_Temp_json = JSON.stringify(Detalle_ventaTemp_);
    

      // generar factura
      var generar_factura_Temp = (noFactura_temp:any) => {

        var itbis_Temp:any  = parseFloat(itbis);
        var total_Temp:any  = parseFloat(total);
        itbis_Temp = itbis_Temp.toFixed(2);
        total_Temp = total_Temp.toFixed(2);

          if (noFactura_temp) {
            this.GetAll_Detalles_Ventas_Json(NoFactura).then( (data:any) => {
                if (data['Detalles_Ventas']) {  
                    // GENERAMOS LA FACTURA :)
                    this.facturacion.PDF_factura_pequena(noFactura_temp, data['Detalles_Ventas'],
                      fechaParaFactura, tipoNcf, cliente, ncfFactura, itbis_Temp, total_Temp,
                       le_Atendio,  metodoPago, recibido, cambio, rncCliente );
                }else{}
            });

          
          }  
            
      }; 


      // let NoFactura_Unica = moment().format("DD-MM-YYYY hh:mm:ss:sss");
        //var mensajeExitoso:any = this.Dialogo_exitoso('Venta Registrada!');

       $.ajax({
         type: "POST",
         url: this.insertar_detalles_venta_json,
         data: {
          NoFactura: NoFactura, 
          arrayDetalles : Array_Temp_json
         }, 
         cache: false,
         success: function(){
         // exitoso!
          // mensajeExitoso
           localStorage.removeItem('Detalle_ventaTemporar');


            // CONSULTAR FACTURA QUE INSERTAMOS
            generar_factura_Temp(NoFactura);
            
          
         }
     });


    }else{
      // this.array_VentaTemporal = [];
    }
    // this.obtenerTotal_ventaTemp();
  }



  public GetAll_Ventas_Json() {
    return this.http.get(this.get_all_ventas_json).toPromise();
  }
  public GetAll_Ventas_Por_Fechas_Json(fechaDesde:string, fechaHasta:string) {
    return this.http.get(this.get_all_ventas_por_fechas_json+'?fechaDesde=' + fechaDesde + 
                        '&fechaHasta='+fechaHasta).toPromise();
  }
  public GetAll_Detalles_Ventas_Json(noFactura:string) {
    //const formData = new FormData();
    // formData.append("noFactura", noFactura);
    return this.http.get(this.get_all_detalles_ventas_json+'?noFactura='+noFactura).toPromise();
  }

  



  

  // 1 PRODUCTOS
  public obtenerProductosJson() {
    return this.http.get(this.listaProductoJson).toPromise();
  }
  public Sincronizar_Productos_Json() {
    this.obtenerProductosJson().then((data: any) => {
      if (data['Productos_Ionic']) {
        if (localStorage.getItem('listadoProductos')) {
          localStorage.removeItem('listadoProductos');
          localStorage.setItem('listadoProductos', JSON.stringify(data['Productos_Ionic']));
        } else {
          localStorage.setItem('listadoProductos', JSON.stringify(data['Productos_Ionic']));
        }
        console.log('PRODUCTO ACTUALIZADOS!');
      }
    })
  }


  // 2 CLIENTES  
  public obtenerClientesJson() {
    return this.http.get(this.listaClienteJson).toPromise();
  }
  public Sincronizar_Clientes_Json() {
    this.obtenerClientesJson().then((data: any) => {
      if (data['Clientes_Ionic']) {
        if (localStorage.getItem('listadoClientes')) {
          localStorage.removeItem('listadoClientes');
          localStorage.setItem('listadoClientes', JSON.stringify(data['Clientes_Ionic']));
        } else {
          localStorage.setItem('listadoClientes', JSON.stringify(data['Clientes_Ionic']));
        }
        console.log('CLIENTES ACTUALIZADOS!')
      }
    })
  }


  // 3 CATEGORIAS  
  public obtenerCategoriaJson() {
    return this.http.get(this.listaCategoriaJson).toPromise();
  }
  public Sincronizar_Categoria_Json() {
    this.obtenerCategoriaJson().then((data: any) => {
      if (data['Categorias_Ionic']) {
        if (localStorage.getItem('listadoCategoria')) {
          localStorage.removeItem('listadoCategoria');
          localStorage.setItem('listadoCategoria', JSON.stringify(data['Categorias_Ionic']));
        } else {
          localStorage.setItem('listadoCategoria', JSON.stringify(data['Categorias_Ionic']));
        }
        console.log('CATEGORIA ACTUALIZADAS!')
      }
    })
  }


  // 4 DEPARTAMENTOS  
  public obtenerDepartamentosJson() {
    return this.http.get(this.listaDepartamentosJson).toPromise();
  }
  public Sincronizar_Departamentos_Json() {
    this.obtenerDepartamentosJson().then((data: any) => {
      if (data['Departamentos_Ionic']) {
        if (localStorage.getItem('listadoDepartamentos')) {
          localStorage.removeItem('listadoDepartamentos');
          localStorage.setItem('listadoDepartamentos', JSON.stringify(data['Departamentos_Ionic']));
        } else {
          localStorage.setItem('listadoDepartamentos', JSON.stringify(data['Departamentos_Ionic']));
        }
        console.log('DEPARTAMENTOS ACTUALIZADAS!')
      }
    })
  }


  // 5 PROVEEDORES  
  public obtenerProveedoresJson() {
    return this.http.get(this.listaProveedoresJson).toPromise();
  }
  public Sincronizar_Proveedores_Json() {
    this.obtenerProveedoresJson().then((data: any) => {
      if (data['Proveedores_Ionic']) {
        if (localStorage.getItem('listadoProveedores')) {
          localStorage.removeItem('listadoProveedores');
          localStorage.setItem('listadoProveedores', JSON.stringify(data['Proveedores_Ionic']));
        } else {
          localStorage.setItem('listadoProveedores', JSON.stringify(data['Proveedores_Ionic']));
        }
        console.log('PROVEEDORES ACTUALIZADAS!')
      }
    })
  }















  //ELIMINAR ELEMENTO DE UN ARRAY
  public Eliminar_From_Array(nombreArray: string,  myIndice: string, valorDeBusqueda:string) {

    if (localStorage.getItem(nombreArray)) {
      var Listado_Storage = localStorage.getItem(nombreArray);
      let listado_TEMP = JSON.parse(Listado_Storage);
      var indice:any;

      switch (myIndice) {
        case 'codigo':
            // obtengo el indice
             indice = listado_TEMP.findIndex(data => data.codigo == valorDeBusqueda);
                break;

        case 'Codigo':
          // obtengo el indice
           indice = listado_TEMP.findIndex(data => data.Codigo == valorDeBusqueda);
              break;
        
        case 'codigoProd':
          // obtengo el indice
            indice = listado_TEMP.findIndex(data => data.codigoProd == valorDeBusqueda);
              break;
        default:
              return
          break;
      }

      // elimino del array
      listado_TEMP.splice(indice, 1);
      localStorage.removeItem(nombreArray);
      localStorage.setItem(nombreArray, JSON.stringify(listado_TEMP));
      listado_TEMP = [];
      this.Dialogo_exitoso('Exitoso!')
      // this.navParams['Braulio']
      // this.navCont.navigateForward('tabs/tab3');
      //location.reload()
    }
  }

  // SINCRONIZAR TODO
  public sincronizar_todo() {

    Swal.fire({
      title: 'Deseas actualizar los datos?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Si, Actualizar',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',

    }).then((result) => {
      try {
        if (result.dismiss.toString() == 'cancel') {

          this.comprobantesS.Guardar_Comprobantes_En_Storage();
          this.serviceAjax.insertar_TodosMySQL();
          this.Dialogo_CargandoEspere();
        }
      } catch (error) {}
    })
  }










  // VENTAS TEMPORALES
    //MODIFICAR CANTIDAD



  // DIALOGOS
  Dialogo_Success_Error(miTitulo:any, MiIcono:any) {

    let Mytimer = 0;
    if (MiIcono == 'error' || MiIcono == 'info') {
      Mytimer = 4000
    } else {
      Mytimer = 2000
    }

    Swal.fire({
      //position: 'top-end',
      position: 'center',
      icon: MiIcono,
      title: miTitulo,
      showConfirmButton: false,
      timer: Mytimer
    });

    if (miTitulo == "Pedido Finalizado!") {
      this.navCont.navigateForward('tabs/clientes');
    } else if (miTitulo == "Pedido enviado con Exito!") {
      this.navCont.navigateForward('tabs/pedidos');
    }

  }



  Dialogo_exitoso(mensaje){
    //bien
    const Toast = Swal.mixin({
      toast: true,
      // position: 'top-end',
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'success',
      title: '' + mensaje
    })
  // fin bien
  }

  // Dialogo espere 1
  Dialogo_CargandoEspere(){
    let timerInterval
    Swal.fire({
      title: 'Sincronizando ...',
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              // b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
          this.Sincronizar_Categoria_Json();
          this.Sincronizar_Clientes_Json();
          this.Sincronizar_Departamentos_Json();
          this.Sincronizar_Productos_Json();
          this.Sincronizar_Proveedores_Json();

          this.Dialogo_CargandoEspere_2();
      }
    })
  }
  // Dialogo espere 2
  Dialogo_CargandoEspere_2(){
    let timerInterval
    Swal.fire({
      title: 'Finalizando ...',
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              // b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
          this.Dialogo_Success_Error('Registros Actualizados!', 'success');
      }
    })
  }







//genera un codigo QR de un enlace o etc...
public generar_codigo_QR(stringQR: string){
  this.pdf.add(new QR(stringQR).fit(100).end);
  // .fit(50)  small
  // .fit(100)  L
  this.pdf.create().open();
}

}
