import { Injectable } from '@angular/core';
import { Canvas, Cell, Img, Line, PdfMakeWrapper, QR, SVG, Table, TextReference, Txt } from 'pdfmake-wrapper';
import * as moment from 'moment';
import { PeticionesService } from './peticiones.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class FacturacionesService {

  link_insert_new_noComprobante:string;

  // constructor...
  constructor(public http: HttpClient) { 
    this.link_insert_new_noComprobante = 
    'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/insert_new_noComprobante.php';
  }






// Incremental No.Comprobante
public Insertar_new_noComprobante(nombre_Comprobante:string){
  const formData = new FormData();
  
  formData.append("nombre_Comprobante", nombre_Comprobante);

  return this.http.post(this.link_insert_new_noComprobante, formData).toPromise();
}


  //GENERAR FACTURA
public async PDF_factura_pequena(noFactura:any, arrayTemp:any[], fechaParaFactura:any,
    tipoNcf:any, cliente:any, ncfFactura:any, itbis:any, total:any, le_Atendio:any,  
    metodoPago:any, recibido:any, cambio:any, rncCliente:any){

                        // MOSTRAR-IMAGEN
    // pdf.add(
    //   await new Img('https://sisboa.net/SisBOA-WEB/Productos_/Imagenes/inventario.jpeg').build()
    // );


    if (arrayTemp) {

        const pdf = new PdfMakeWrapper();

        
      //pdf.pageSize('A4');
        pdf.pageSize({
          width: 180,
          height: 'auto'
        });

      // margin
      pdf.pageMargins([ 5, 5, 5, 10 ]);

                          // CABEZAL
      pdf.add(
        new Txt('SisBOA \n')
        .bold().alignment('center').fontSize(12).end
      );

                          // DETALLES EMPRESA (pendiente)
        pdf.add(
          new Txt('RNC: 01020101101 \n'+
          'C/ PRIMERA #77 STO. DGO. ESTE\n'+
          'TEL: 829-937-6041 \n')
          .alignment('center').fontSize(7).end
        );

                          // DETALLES Y CLIENTE +

        var rncCliente_Temp:string ='';
        if (rncCliente != "0" && rncCliente != "" && rncCliente != undefined && rncCliente != 'undefined'  ) {
          rncCliente_Temp = 'Rnc: '+rncCliente+'\n';
        }else{
          rncCliente_Temp = '';
        }
        pdf.add(
          new Txt(
          'Fecha: '+fechaParaFactura + '\n'+
          rncCliente_Temp +
          'Cliente: '+cliente+'\n'+
          'No.Factura: '+noFactura+'\n'+
          'NCF: '+ncfFactura)
          .alignment('left').fontSize(7).end
        );


//linea 
pdf.add(this.linea_1_jump());

                        //TIPO DE COMPROBANTE
        pdf.add(
          new Txt(
            'FACTURA '+tipoNcf)
          .bold().italics().fontSize(6).margin([ 0, 0, 0, 0 ]).alignment('center').end
        );

//linea 
pdf.add(this.linea_2_jump());


                        // DESCRIPCIÓN  - ITBIS - VALOR
        pdf.add(
          new Table([
          [ 'DESCRIPCIÓN', 'ITBIS', 'VALOR'],
          ])
          .widths([ 50, 20, 100 ]).fontSize(7).bold().layout('noBorders').alignment('left').end
          )


                      // DETALLES DE LA FACTURA
        arrayTemp.forEach(element => {

          //  PARA MOSTRAR TIPO DE ITBIS
          //var miTipoItbis:any = this.comprobar_tipoItbis(element.TipoItbis);

          var cantidad:any  = parseFloat(element.Cantidad);
          var Precio:any  = parseFloat(element.Precio);
          var subTotal_temp:any = cantidad * Precio;
          
          cantidad = cantidad;
          Precio = Precio.toFixed(2);
          subTotal_temp = subTotal_temp.toFixed(2);

            pdf.add(
              new Table([
            [ 
            cantidad+' X '+ this.fomatNum(Precio)  +'\n'+element.Descripcion,
            this.fomatNum(element.Itbis),
            this.fomatNum(subTotal_temp)
            //this.fomatNum(subTotal_temp) + ' ' + miTipoItbis
            

            ]

            ]).widths([60, 25, 40]).fontSize(6).layout('noBorders')
            .alignment('left').end)
            

          }); // fin for


//linea 
pdf.add(this.linea_1_jump());

                      // SUB-TOTALES
        pdf.add(
          new Table([    
          [ 'SUBTOTAL', this.fomatNum(itbis) ,   this.fomatNum(total) ]
          ]).widths([ 50, 50, 80 ]).fontSize(5).layout('noBorders')
          .alignment('left').bold().italics().end)

        //linea 
        pdf.add(this.linea_2_jump());

                      // TOTALES
        pdf.add(
          new Table([
          [ 'TOTAL', '', this.fomatNum(total)]
        ])
          .widths([ 50, 50, 50 ]).fontSize(5).layout('noBorders')
          .alignment('left').margin([ 0, 0, 55, 0 ]).bold().fontSize(7).end)



                    // MODO-PAGO, CAMBIO Y CAJERO:
                    
        var recibidoTemp:any = parseFloat(recibido);
        recibidoTemp = recibidoTemp.toFixed(2);
        recibidoTemp = this.fomatNum(recibidoTemp);

        var cambioTemp:any = parseFloat(cambio);
        cambioTemp = cambioTemp.toFixed(2);
        cambioTemp = this.fomatNum(cambioTemp);

        pdf.add(
        new Table([    
        [ metodoPago, ' ' ,   recibidoTemp ],
        [ 'CAMBIO: ',   ' ' , cambioTemp ],
        [ 'ATENDIDO POR: '+le_Atendio,   ' ' , ' ']
          ])
          .widths([ 60, 50, 80 ]).fontSize(4).layout('noBorders')
          .alignment('left').bold().italics().end)
        //.layout('lightHorizontalLines')    (funciono la linea)


        //linea 
        pdf.add(this.linea_2_jump());



                    // GRACIAS POR PREFERIRNOS!
          pdf.add(
            new Txt('GRACIAS POR SU COMPRA! \n\n')
            .bold().italics().alignment('center').fontSize(5).end
          );


          // var win = window.open('', '_self');

          // pdf.create('').print({}, win);
            pdf.create().print();
        //ABRIMOS PDF  
        // pdf.create().open();

      } //no existe dato


      else{ 
        this.Dialogo_Success_Error('No tienes conexión a internet!','info');
      }

}
public async PDF_factura_grande_8_2_X_11(noFactura:any, arrayTemp:any[],  fechaParaFactura:any,
  tipoNcf:any, cliente:any, ncfFactura:any, itbis:any, total:any){

    const pdf = new PdfMakeWrapper();

    pdf.add(
            new Txt('SisBOA \n\n').bold().italics().end
          );
    
    
      pdf.add(
        new Table([
          ['Descripcion', 'Cant', 'Precio', 'Sub-Total'],
          [ 'COMPUTADORA', 3, 2000, 4000],
          [ 'LAPTOP', 2, 3000, 8000],
          [ 'TABLET', 12, 25000, 4000],
          [ 'MAUSE', 50, 4000, 400],
          [ 'FORRO PARA LAPTOP 15', 100, 600, 7000]
      ]).widths([250, 50, 50, 70])
      .layout({
        fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
            // return rowIndex %2 === 0 ? '#f1f3f4': '';
            return rowIndex  === 0 ? '#f1f3f4': '';
          }
    }).end
      )
    
    //.widths([ '*', 100 ])
    
      pdf.create().open();


  
  }
//saltos de lineas
public linea_1_jump(){

  return  new 
  Txt('-----------------------------------------------------------------------------\n')
  .bold().italics().fontSize(6).margin([ 0, 0, 0, 0 ]).end
}
public linea_2_jump(){
  return new 
  Txt('\n-----------------------------------------------------------------------------\n')
  .bold().italics().fontSize(6).margin([ 0, 0, 0, 0 ]).end
}


/*
generatePDF() {
  var win = window.open('', '_self');

  this.pdf.createPdf(documentDefinition).print({}, win);
  
  setTimeout (() => {
      const documentDefinition = {
        content: 'CONTENIDO DEL PDF'
      };
      this.pdf.createPdf(documentDefinition).print({}, win);
  }, 1000)
}
*/


          //METODOS UTILES
//public comprobar tipo de Itbis
public comprobar_tipoItbis(miTipoItbis:any){
  var valorRetorno:any = 0;
      if (miTipoItbis == '1') {
          valorRetorno = '(18%)';
      }else if (miTipoItbis == '2') {
          valorRetorno = '(16%)';
      }else if (miTipoItbis == '0') {
          valorRetorno = '';
      }else{
          valorRetorno= '';
      }
return valorRetorno;
}
// conviertidor en formato de miles
public fomatNum = (number:any) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  return number.toString().replace(exp,rep);
}


        // DIALOGOS
  Dialogo_Success_Error(miTitulo:any, MiIcono:any) {

    let Mytimer = 0;
    if (MiIcono == 'error' || MiIcono == 'info') {
      Mytimer = 4000
    } else {
      Mytimer = 2000
    }

    Swal.fire({
      //position: 'top-end',
      position: 'center',
      icon: MiIcono,
      title: miTitulo,
      showConfirmButton: false,
      timer: Mytimer
    });

  }

}
