import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-add-producto',
  templateUrl: './modal-add-producto.page.html',
  styleUrls: ['./modal-add-producto.page.scss'],
})
export class ModalAddProductoPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
