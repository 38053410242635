import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';

@Component({
  selector: 'app-crearnueva-categoria',
  templateUrl: './crearnueva-categoria.page.html',
  styleUrls: ['./crearnueva-categoria.page.scss'],
})
export class CrearnuevaCategoriaPage implements OnInit {

  @Input() codigoCategoriaTraida: string
  @Input() categoriaTraida: string
  @Input() permisoEditarCategoriaTraida: string

  //Datos del categoria:
  codigo_AUTO:string;
  Categoria:string;
  
  constructor(public peticiones: PeticionesService, public modalController: ModalController,
    public alertController: AlertController, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }
  salir_sin_parametro(){
    this.modalController.dismiss();
    }


    ionViewWillEnter() {
      this.GetCategoria_LocalStorage();
    }
    
    public GetCategoria_LocalStorage(){
      if(localStorage.getItem(this.myLocalStorage.listado_Categorias)){
        var ListadoTem_Storage = localStorage.getItem(this.myLocalStorage.listado_Categorias);
        let listado_Temporal = JSON.parse(ListadoTem_Storage);

        // BUSCANDO CODIGO
        var ArrayNumber = [];
        listado_Temporal.forEach(element => {
          ArrayNumber.push(element['Codigo']);
        });

        let numeroMayor = Math.max.apply(null,ArrayNumber);
        var codigoPreparado = '';
        
        if (numeroMayor != -Infinity) {
            // '00'+ (numeroMayor+1)
            codigoPreparado = '00'+ (numeroMayor+1);

        }else{    codigoPreparado = '001';
         }



        if (this.categoriaTraida) {
          this.codigo_AUTO = this.codigoCategoriaTraida;
          this.Categoria = this.categoriaTraida;
        }else{
          this.codigo_AUTO = codigoPreparado;
        }

        //console.log(this.codigo_AUTO)
      }
    }


    //  GUARDAR O EDITAR
    public async dialogo_confirmar_guardar_categoria(guardar_Editar:string){

      if (this.Categoria) {
      const alertImagenProducto =  await  this.alertController.create({
        header:  'Desea '+guardar_Editar+' la categoría:',
        message:  this.Categoria+' ?',
        // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
        cssClass: 'my-custom-class',

      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA

          }
        }, {
          role:'aceptar',
          text: guardar_Editar+' ✅',
          handler: (valor) => {
                // aceptar

                  //Guardar o Editar


      
                   if(localStorage.getItem(this.myLocalStorage.listado_Categorias)){

                    if (guardar_Editar == 'editar') {
                      this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Categorias, 'Codigo',this.codigo_AUTO);
                    }


                  var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Categorias);
                  let listado_Temporal:any = JSON.parse(ListadoTemp_Storage);

                  // console.log(listado_Temporal)


                  listado_Temporal.push(
                    {
                    Codigo: this.codigo_AUTO.toUpperCase(),
                    Categoria: this.Categoria.toUpperCase(),
                  });
                  localStorage.removeItem(this.myLocalStorage.listado_Categorias);
                  localStorage.setItem(this.myLocalStorage.listado_Categorias, JSON.stringify(listado_Temporal));
                  this.modalController.dismiss({
                    categoria: 'exitosa!'
                  });
                }

          }
        }
      ]
        });
        alertImagenProducto.present()

      }//no existe nombre del cliente
      else{
        //si no exite un nombre:
      }
      }


}
