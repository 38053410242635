import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalDepartamentosPageRoutingModule } from './modal-departamentos-routing.module';

import { ModalDepartamentosPage } from './modal-departamentos.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ModalDepartamentosPageRoutingModule
  ],
  declarations: [ModalDepartamentosPage]
})
export class ModalDepartamentosPageModule {}
