import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';

@Component({
  selector: 'app-ventas-agendadas',
  templateUrl: './ventas-agendadas.page.html',
  styleUrls: ['./ventas-agendadas.page.scss'],
})
export class VentasAgendadasPage implements OnInit {
  arrayVentasAgendadas:any[];
  totalVentasAgendadas:any;

  inputFiltrar:string;

  constructor(public alertController: AlertController, public myLocalStorage: LocalstoragesService,
    public modalController: ModalController, public peticiones:PeticionesService) { 

    // this.arrayVentasAgendadas = [
    //   {codigo:'00001', nombre:'Braulio', total:10000},
    //   {codigo:'00002', nombre:'Briam', total:8000},
    //   {codigo:'00003', nombre:'Jose', total:7000},
    //   {codigo:'00004', nombre:'Pedro', total:6000},
    //   {codigo:'00005', nombre:'Marcos', total:5000}
    // ]

 
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.getVentas_Agendadas('mostrar');
  }


  salir_sin_parametro(){
    this.modalController.dismiss();
  }


    // DIALOGO RECUPERAR VENTA AGENDADA
    public async dialogo_recuperar_venta_agendada(noVenta:string){
          var NoVenta_int = parseInt(noVenta);
      const alertImagenProducto =  await  this.alertController.create({
  
        header:  'Cargar la venta #'+(parseInt(noVenta)+1)+'?',
         message: 'Nota: Si tienes una venta en curso los datos que no hayas guardado se perderán',
        // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
        cssClass: 'my-custom-class',
  
      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA
  
          }
        }, {
          role:'aceptar',
          text: 'Cargar +',
          handler: (valor) => {
                // aceptar
                this.recuperar_venta_agendada(NoVenta_int);
          }
        }
      ]

        });
        alertImagenProducto.present()
      }

    public recuperar_venta_agendada(noVenta:number){
          if (localStorage.getItem(this.myLocalStorage.Venta_Temporal)) {
                        
            localStorage.removeItem(this.myLocalStorage.Venta_Temporal);
            localStorage.setItem(this.myLocalStorage.Venta_Temporal,
            JSON.stringify(this.arrayVentasAgendadas[noVenta]['venta_temporal']));  
            //venta recuperada
            this.eliminar_venta_agendada(noVenta, '');
            this.modalController.dismiss({
              recargarListado: 'Recarga!',
          })
              
        }else{
            localStorage.setItem(this.myLocalStorage.Venta_Temporal,
            JSON.stringify(this.arrayVentasAgendadas[noVenta]['venta_temporal']));  
            //venta recuperada
            this.eliminar_venta_agendada(noVenta,'');
            this.modalController.dismiss({
                recargarListado: 'Recarga!',
            })

    }

    }




    // DIALOGO ELIMINAR VENTA EN COLA
    public async dialogo_eliminar_venta_agendada(noVenta:string){

      var NoVenta_int = parseInt(noVenta);

      const alertImagenProducto =  await  this.alertController.create({
  
        header:  'Eliminando Venta #'+(parseInt(noVenta)+1)+'...',
         message: 'Desea eliminar esta venta?',
        // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
        cssClass: 'my-custom-class',
  
      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA
  
          }
        }, {
          role:'aceptar',
          text: 'Eliminar - ',
          handler: (valor) => {
                // aceptar
                  this.eliminar_venta_agendada(NoVenta_int,'Registro Eliminado!')
          }
        }
      ]
        });
        alertImagenProducto.present()
      }


    public eliminar_venta_agendada(noVenta:number, mensajeDialogo:string){
          if (localStorage.getItem(this.myLocalStorage.Ventas_Agendadas)) {
            let ventaTempArchivada_Storage = localStorage.getItem(this.myLocalStorage.Ventas_Agendadas);
            let Ventas_Agendada = JSON.parse(ventaTempArchivada_Storage);

            Ventas_Agendada.splice(noVenta, 1);
            localStorage.removeItem(this.myLocalStorage.Ventas_Agendadas);
            localStorage.setItem(this.myLocalStorage.Ventas_Agendadas, JSON.stringify(Ventas_Agendada)); 
            this.peticiones.Dialogo_exitoso(mensajeDialogo);
            this.getVentas_Agendadas('');
          }   

        }

    public getVentas_Agendadas(mostrarMensaje:string){
      if (localStorage.getItem(this.myLocalStorage.Ventas_Agendadas)) {
      let ventaTempArchivada_Storage = localStorage.getItem(this.myLocalStorage.Ventas_Agendadas);
      let Ventas_Agendada = JSON.parse(ventaTempArchivada_Storage);

      this.arrayVentasAgendadas = Ventas_Agendada;
      this.totalVentasAgendadas = this.arrayVentasAgendadas.length;
      
      if ( this.arrayVentasAgendadas.length == 0) {
            if (mostrarMensaje == 'mostrar') {
              this.peticiones.Dialogo_Success_Error('No hay ventas agendadas!','info')
            }
            this.modalController.dismiss({
              reinicia:'Recarga!'
            })
      }

      }else{
        this.arrayVentasAgendadas = [];
      }



    }




}
