import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { data } from 'jquery';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

 public usuarioLogueado:any = {
      nombre:'',
      tipoUsuario:'',
      permiso:''
    };

    enlace_usuario_Json:string ='';

  constructor(public http: HttpClient, public navCtrl: NavController) {  
    this.enlace_usuario_Json = 'https://sisboa.net/SisBOA-WEB/Login-User/Get_User.php?';
  }


  public Link_User_Json(correo:string , clave:string) {
    return this.http.get(this.enlace_usuario_Json+'correo='+correo+'&clave='+clave).toPromise();
  }

  public Get_User_Json(correo:string , clave:string) {
            this.Link_User_Json(correo, clave).then((data:any)=>{
                    if (data['Usuarios']) {
                          this.usuarioLogueado = {
                            nombre: data['Usuarios'][0].user,
                            tipoUsuario: data['Usuarios'][0].tipoUsuario,
                            permiso: data['Usuarios'][0].permiso
                          }

                          this.Validar_Usuario_Conectado();
                          console.log('User Conected!')
                    }else{
                        this.usuarioLogueado = {
                          nombre:'',
                          tipoUsuario:'',
                          permiso:''
                        }

                        this.Dialogo_Success_Error('Coreeo o contraseña incorrecto!', 'info');
                    }
            })
  }

  public Validar_Usuario_Conectado(){
    if (!this.usuarioLogueado.nombre) {
                  this.Dialogo_Success_Error('Debes iniciar sesión!', 'info')
                  this.navCtrl.navigateForward('login');
                  // this.navCtrl.navigateForward('tabs/administracion');
              }else{
                //this.navCtrl.navigateForward('tabs/administracion');
                  this.navCtrl.navigateForward('tabs/tab2');
              }
  }


  Dialogo_Success_Error(miTitulo:any, MiIcono:any) {

    let Mytimer = 0;
    if (MiIcono == 'error' || MiIcono == 'info') {
      Mytimer = 4000
    } else {
      Mytimer = 2000
    }

    Swal.fire({
      //position: 'top-end',
      position: 'center',
      icon: MiIcono,
      title: miTitulo,
      showConfirmButton: false,
      timer: Mytimer
    });


  }


}
