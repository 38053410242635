import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';

@Component({
  selector: 'app-crearnuevo-proveedor',
  templateUrl: './crearnuevo-proveedor.page.html',
  styleUrls: ['./crearnuevo-proveedor.page.scss'],
})

export class CrearnuevoProveedorPage implements OnInit {

  @Input() codigoEDIT: string
  @Input() proveedorEDIT: string

  @Input() direccionEDIT: string
  @Input() telefonoEDIT: string
  @Input() rncEDIT: string
  @Input() correoEDIT: string
 
  @Input() permisoEditarproveedor: string



  //Datos departamentos
  codigo_AUTO:string;
  Proveedor:string;
  direccion:string = '';
  telefono:string= '';
  rnc:string= '';
  correo:string= '';



  constructor(public peticiones: PeticionesService, public modalController: ModalController,
    public alertController: AlertController, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.GetDepartamento_LocalStorage();
  }

  public GetDepartamento_LocalStorage(){
    if(localStorage.getItem(this.myLocalStorage.listado_Proveedores)){
      var ListadoTem_Storage = localStorage.getItem(this.myLocalStorage.listado_Proveedores);
      let listado_Temporal = JSON.parse(ListadoTem_Storage);



        // BUSCANDO CODIGO
        var ArrayNumber = [];
        listado_Temporal.forEach(element => {
          ArrayNumber.push(element['Codigo']);
        });

        let numeroMayor = Math.max.apply(null,ArrayNumber);
        var codigoPreparado = '';
        
        if (numeroMayor != -Infinity) {
            // '00'+ (numeroMayor+1)
            codigoPreparado = '00'+ (numeroMayor+1);

        }else{    codigoPreparado = '001';
         }
        // FIN/ BUSCANDO CODIGO



      if (this.codigoEDIT) {
        this.codigo_AUTO = this.codigoEDIT;
        this.Proveedor = this.proveedorEDIT;
        this.direccion = this.direccionEDIT;
        this.telefono = this.telefonoEDIT;
        this.rnc = this.rncEDIT;
        this.correo = this.correoEDIT;
      }else{
        this.codigo_AUTO = codigoPreparado;
      }
      //console.log(this.codigo_AUTO)
    }
  }

  salir_sin_parametro(){
    this.modalController.dismiss();
    }

  public async dialogo_confirmar_guardar_proveedor(guardar_Editar:string){
    if (this.Proveedor) {
    const alertImagenProducto =  await  this.alertController.create({
      header:  'Desea '+guardar_Editar+' el proveedor:',
      message:  this.Proveedor+' ?',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',

    buttons: [
      {
        text: 'Cancelar',
        role: 'destructive',
        handler: (valor) => {
          // NADA

        }
      }, {
        role:'aceptar',
        text: guardar_Editar+' ✅',
        handler: (valor) => {
              // aceptar
              if(localStorage.getItem(this.myLocalStorage.listado_Proveedores)){

                if (guardar_Editar == 'editar') {
                  this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Proveedores, 'Codigo',this.codigo_AUTO);
                }
                var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Proveedores);
                let listado_Temporal:any = JSON.parse(ListadoTemp_Storage);

                listado_Temporal.push(
                  {
                  Codigo: this.codigo_AUTO.toUpperCase(),
                  Proveedor: this.Proveedor.toUpperCase(),
                  direccion: this.direccion.toUpperCase(),
                  telefono: this.telefono,
                  rnc: this.rnc,
                  correo: this.correo,
                  activo: '1',
                });
                localStorage.removeItem(this.myLocalStorage.listado_Proveedores);
                localStorage.setItem(this.myLocalStorage.listado_Proveedores, JSON.stringify(listado_Temporal));
                this.modalController.dismiss({
                  departamentos: 'exitoso!'
                });
              }
        }
      }
    ]
      });
      alertImagenProducto.present()

    }//no existe nombre del cliente
    else{
      //si no exite un nombre:
    }
    }

 



}
