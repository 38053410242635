import { Injectable } from '@angular/core';
import * as $ from "jquery";
import { LocalstoragesService } from './localstorages.service';


@Injectable({
  providedIn: 'root'
})
export class ServiceAjaxService {

  constructor(public myLocalStorage: LocalstoragesService) { }


        //INSERTS
public insertar_TodosMySQL(){
  this.insertar_productos_MSQL();
  this.insertar_clientes_MSQL();
  this.insertar_departamentos_MSQL();
}



  //PRODUCTOS   XS 1
  public  insertar_productos_MSQL(){
    if(localStorage.getItem(this.myLocalStorage.listado_Productos)){
      var Listado_Storage = localStorage.getItem(this.myLocalStorage.listado_Productos);
      let listado_Tem:any = JSON.parse(Listado_Storage);

      // preparamos Json para mysql
      var Array_deptos_json = JSON.stringify(listado_Tem);
           
      // REEMPLAZAMOS
          $.ajax({
            type: "POST",
            url: "https://sisboa.net/SisBOA-WEB/Productos_/Insertar_Nuevo.php",
            data: {
              data : Array_deptos_json
            }, 
            cache: false,
            success: function(){
              console.log('Productos sincroniados!')
                
            }
        });



    }
  }

  //CLIENTES     2
  public  insertar_clientes_MSQL(){
    if(localStorage.getItem(this.myLocalStorage.listado_Clientes)){
      var Listado_Storage = localStorage.getItem(this.myLocalStorage.listado_Clientes);
      let listado_Tem:any = JSON.parse(Listado_Storage);

      // preparamos Json para mysql
      var Array_deptos_json = JSON.stringify(listado_Tem);
            
      // REEMPLAZAMOS
          $.ajax({
            type: "POST",
            url: "https://sisboa.net/SisBOA-WEB/Clientes_/Insertar_Nuevo.php",
            data: {
              data : Array_deptos_json
            }, 
            cache: false,
            success: function(){
              console.log('Clientes sincroniados!')
            }
        });


        
    }
  }

  //DEPARTAMENTOS 3
  public  insertar_departamentos_MSQL(){
    if(localStorage.getItem(this.myLocalStorage.listado_Departamentos)){
      var Listado_Storage = localStorage.getItem(this.myLocalStorage.listado_Departamentos);
      let listado_Tem:any = JSON.parse(Listado_Storage);

      // preparamos Json para mysql
      var Array_deptos_json = JSON.stringify(listado_Tem);

           // PRIMERO BORRAMOS Y LUEGO INSERTAMOS
      $.ajax({
        type: "POST",
        url: "https://sisboa.net/SisBOA-WEB/Departamentos/Delete.php",
        cache: false,
        success: function(){

          // DESPUES DE BORRAR LA TABLA INSERTAMOS
                $.ajax({
                  type: "POST",
                  url: "https://sisboa.net/SisBOA-WEB/Departamentos/Insertar_Nuevo.php",
                  data: {
                    data : Array_deptos_json
                  }, 
                  cache: false,
                  success: function(){
                    console.log('Departamentos sincroniados!')
                  }
              });
              // fin  
        }
    });

    }
  }





}
