import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CrearnuevoDepartamentoPage } from './crearnuevo-departamento/crearnuevo-departamento.page';
import Swal from'sweetalert2';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';

@Component({
  selector: 'app-modal-departamentos',
  templateUrl: './modal-departamentos.page.html',
  styleUrls: ['./modal-departamentos.page.scss'],
})
export class ModalDepartamentosPage implements OnInit {


  inputFiltrar:string;
  listadoDepartamento:any[];



  constructor(private modalController: ModalController, public peticiones: PeticionesService,
    public alertController: AlertController, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.GetDepartamentos_LocalStorage();
  }

  salir_sin_parametro(){
    this.modalController.dismiss();
    }



  public FiltrarDepartamento(){
    if(localStorage.getItem(this.myLocalStorage.listado_Departamentos)){
      //console.log('Si existen clientes')

      var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Departamentos);
      let listadoTem:any = JSON.parse(ListadoTemp_Storage);
      //console.log(listadoClientesTem) 

      let listadoFiltrado = listadoTem.filter(
      data => data.Codigo.includes (this.inputFiltrar.toUpperCase()) ||
      data.Departamento.includes (this.inputFiltrar.toUpperCase()));
      this.listadoDepartamento = listadoFiltrado

    }else{
      console.log('No existen departamento')
    }
  }

  // GET DEPARTAMENTOS
  public GetDepartamentos_LocalStorage(){
    if(localStorage.getItem(this.myLocalStorage.listado_Departamentos)){
      var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Departamentos);
      this.listadoDepartamento = JSON.parse(ListadoTemp_Storage);
      //console.log(this.listadoClientes)
    }
  }
  
  // CREANDO NUEVO DEPARTAMENTO
  public async openNuevoDepartamento(codigoEDIT:string, departamentoEDIT:string, editar:string){
    try {
          const modal = await this.modalController.create({
            component: CrearnuevoDepartamentoPage,
            cssClass: 'my-custom-class',
            componentProps: {
              codigoEDIT: codigoEDIT,
              departamentoEDIT: departamentoEDIT,
              permisoEditarDepartamento: editar
            },
          });

          await modal.present();
          //para recibir datos
        try {
              const {data} = await modal.onDidDismiss()
              if(data){
                //  console.log('Direccion: '+data.direccion + '\n Codigo'+ data.codigo + '\n Negocio' +data.negocio)
               this.GetDepartamentos_LocalStorage();
            }else{
                // this.MostrarPedidosJson();
              }
          } catch (error) {
            console.log('error al leer datos no existe!')
          }
            
    } catch (error) {
          // this.peticiones.Dialogo_Error_Condiciones('No Tienes Un Pedido Seleccionado');
    }

      }

  public dialogo_confirmar_departamento(departamento:string){
    this.modalController.dismiss({
      departamento:departamento
    });
  }




            //ELIMINAR
            public async dialogo_Eliminar(codigo:string, departamento:string){

              const alertImagenProducto =  await  this.alertController.create({
          
                header:  'Seguro que desea eliminar este registro?',
                message: departamento+'',
                // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
                cssClass: 'my-custom-class',
          
              buttons: [
                {
                  role:'destructive',
                  text: 'CANCELAR',
                  handler: (valor) => {
                          // OK
      
      
          
                  }
                },
                {
                  role:'aceptar',
                  text: 'ELIMINAR',
                  handler: (valor) => {
                          // OK
                      this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Departamentos, 'Codigo',codigo);
                      this.Dialogo_Espere('Eliminando','Registro Eliminado');
      
                  }
                }
              ]
                });
                alertImagenProducto.present()
              }
              // Espere 3 segundos ...
            public Dialogo_Espere(titulo_, mensajeDialogo){
              let timerInterval
              Swal.fire({
                title: titulo_,
                html: '',
                timer: 2500,
                timerProgressBar: true,
                showConfirmButton:false,
                willOpen: () => {
                  Swal.showLoading()
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent()
                    if (content) {
                      const b = content.querySelector('b')
                      if (b) {
                      }
                    }
                  }, 100)
                },
                willClose: () => {
                  clearInterval(timerInterval)
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  //despues que finaliza
                    this.peticiones.Dialogo_exitoso(mensajeDialogo);
                    this.GetDepartamentos_LocalStorage();
                }
              })
            }




}
