import { Component, Input, OnInit } from '@angular/core';
import { PeticionesService } from 'src/app/Providers/peticiones.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() myTitulo :string = '';

  constructor(public peticiones: PeticionesService) { }

  ngOnInit() {}

}
