import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CrearnuevoProveedorPage } from './crearnuevo-proveedor/crearnuevo-proveedor.page';
import Swal from'sweetalert2';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';

@Component({
  selector: 'app-modal-proveedor',
  templateUrl: './modal-proveedor.page.html',
  styleUrls: ['./modal-proveedor.page.scss'],
})
export class ModalProveedorPage implements OnInit {

  inputFiltrar:string;
  listadoProveedores:any[];

  constructor(private modalController: ModalController, public peticiones: PeticionesService,
    public alertController: AlertController, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }

  salir_sin_parametro(){
    this.modalController.dismiss();
    }



  ionViewWillEnter() {
    this.GetProveedores_LocalStorage();
  }

  public FiltrarDepartamento(){
    if(localStorage.getItem(this.myLocalStorage.listado_Proveedores)){
      //console.log('Si existen clientes')

      var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Proveedores);
      let listadoTem:any = JSON.parse(ListadoTemp_Storage);
      //console.log(listadoClientesTem) 

      let listadoFiltrado = listadoTem.filter(
      data => data.Codigo.includes (this.inputFiltrar.toUpperCase()) ||
      data.Proveedor.includes (this.inputFiltrar.toUpperCase()) ||
      data.rnc.includes (this.inputFiltrar.toUpperCase()));
      this.listadoProveedores = listadoFiltrado

    }else{
      console.log('No existen departamento')
    }
  }

  // GET PROVEEDORES
  public GetProveedores_LocalStorage(){
    if(localStorage.getItem(this.myLocalStorage.listado_Proveedores)){
      var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Proveedores);
      this.listadoProveedores = JSON.parse(ListadoTemp_Storage);
      //console.log(this.listadoClientes)
    }
  }
  
  // CREANDO NUEVO PROVEEDOR
  public async openNuevoProveedor(codigoEDIT:string, proveedorEDIT:string,
    direccionEDIT:string, telefonoEDIT:string, rncEDIT:string,correoEDIT:string, editar:string){
    try {
          const modal = await this.modalController.create({
            component: CrearnuevoProveedorPage,
            cssClass: 'my-custom-class',
            componentProps: {
              codigoEDIT: codigoEDIT,
              proveedorEDIT: proveedorEDIT,
              direccionEDIT: direccionEDIT,
              telefonoEDIT:telefonoEDIT,
              rncEDIT: rncEDIT,
              correoEDIT:correoEDIT,

              permisoEditarproveedor: editar
            },
          });
          await modal.present();
          //para recibir datos
        try {
              const {data} = await modal.onDidDismiss()
              if(data){
                //  console.log('Direccion: '+data.direccion + '\n Codigo'+ data.codigo + '\n Negocio' +data.negocio)
               this.GetProveedores_LocalStorage();
            }else{
                // this.MostrarPedidosJson();
              }
          } catch (error) {
            console.log('error al leer datos no existe!')
          }
            
    } catch (error) {
          // this.peticiones.Dialogo_Error_Condiciones('No Tienes Un Pedido Seleccionado');
    }

      }


  public dialogo_confirmar_proveedor(proveedor:string){
    this.modalController.dismiss({
      proveedor:proveedor
    });
  }


  //ELIMINAR
  public async dialogo_Eliminar(codigo:string, categoria:string){

    const alertImagenProducto =  await  this.alertController.create({

      header:  'Seguro que desea eliminar este registro?',
      message: categoria+'',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',

    buttons: [
      {
        role:'destructive',
        text: 'CANCELAR',
        handler: (valor) => {
                // OK



        }
      },
      {
        role:'aceptar',
        text: 'ELIMINAR',
        handler: (valor) => {
                // OK
            this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Proveedores, 'Codigo',codigo);
            this.Dialogo_Espere('Eliminando','Registro Eliminado');

        }
      }
    ]
      });
      alertImagenProducto.present()
    }
    // Espere 3 segundos ...
  public Dialogo_Espere(titulo_, mensajeDialogo){
    let timerInterval
    Swal.fire({
      title: titulo_,
      html: '',
      timer: 2500,
      timerProgressBar: true,
      showConfirmButton:false,
      willOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        //despues que finaliza
          this.peticiones.Dialogo_exitoso(mensajeDialogo);
          this.GetProveedores_LocalStorage();
      }
    })
  }

  


  
}
