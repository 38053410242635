import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import Swal from 'sweetalert2';
import * as $ from "jquery";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-sincronizar-rnc',
  templateUrl: './sincronizar-rnc.page.html',
  styleUrls: ['./sincronizar-rnc.page.scss'],
})
export class SincronizarRNCPage implements OnInit {

  archivoSelect_Text:string = '';
  linkInsert_rnc_mysql:string = 'https://sisboa.net/SisBOA-WEB/Archivo-RNC/insertar_listado_RNC.php';
  // https://sisboa.net/SisBOA-WEB/Archivo-RNC/archivo_insert.php
  
  constructor(public peticiones: PeticionesService, public modalController: ModalController,
    public myLocalStorage:LocalstoragesService,public http: HttpClient) { }

  ngOnInit() {
  }


  salir_sin_parametro(){
    this.modalController.dismiss();
  }




 writeFile() {

  var blob = new Blob(["This is my first text."], {type: "text/plain;charset=utf-8"});
   
  let reader = new FileReader();
  reader.readAsText(blob); 
  reader.onload = ()=> {
    let temp:any = reader.result;
    let lines:any = temp.split(/\r\n|\n/);
    console.log(lines[0])
  }
  saveAs(blob, "testfile1.txt");



}




public sincronizar_listado_clientes_RNC(){
// var archivoRNC:any = 'http://sisboa.net/SisBOA-WEB/Archivo-RNC/n/DGII_RNC.TXT';

  try {  

  var input = document.querySelector('input');

  // inp= fopen(getScriptPath("myFile.txt"), 0);
// this.archivoSelect_Text = archivoRNC;

   /* esquemaTabla = {
      Rnc: element.split('|')[0],
      nombre_empresa: element.split('|')[1],
      Razon_social: element.split('|')[2],
      Ocupacion: element.split('|')[3],
      Direccion: element.split('|')[4],
      Numero: element.split('|')[5],
      Sector: element.split('|')[6],
      Telefono: element.split('|')[7],
      Fecha: element.split('|')[8],
      Estatus: element.split('|')[9],
      Tipo: element.split('|')[10]
    }*/
    let reader = new FileReader();
      reader.readAsText(input.files[0]); 
      reader.onload = ()=> {
        let temp:any = reader.result;
        let lines:any = temp.split(/\r\n|\n/);

        var arrayTemp:any[]=[];
        var esquemaTabla:any;
        var index = 0;

        var RncTemp:string='';
        var Nombre_empresaTemp:string='';
        var EstatusTemp:string='';
        var TipoTemp:string='';
        var replacesData = {'`':' ','ø':'O'};

        var parte1PHP:any = `<?php
        header('Access-Control-Allow-Origin: *');
        header('Access-Control-Allow-Headers: Origin, X-Requested-With, Content-Type, Accept');
        header('Access-Control-Allow-Methods: GET, POST, PUT, DELETE');

          require '../Conexion/conexion.php';
          mysqli_set_charset($conexion, 'utf8'); //linea a colocar
          if(!$conexion){
          echo'No Se Pudo Establecer Conexion Con El Servidor: '. mysql_error();
          }
          else{ $sql = "`;


         var parte3PHP:any = `";

         $ejecuta_sentencia = mysqli_query($conexion, $sql);
         if(!$ejecuta_sentencia){
          echo'hay un error en la sentencia de sql: '.$sql;
          }else{
                        //Todo Perfecto
          }
        }//fin else

        ?>
         `;

        var limon:string = "";
        // limon +=parte1PHP;
        // limon+= "REPLACE INTO Listado_RNC_VALIDOS (Rnc, nombre_empresa, Estatus, Tipo) VALUES ";


        // var txt = new ActiveXObject("Scripting.FileSystemObject");
        // var s;

//INICIO DIALOGO........
  let timerInterval
  Swal.fire({
    title: 'Sincronizando listado ...',
    // html: 'I will close in <b></b> milliseconds.',
    timer: 12000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()
      timerInterval = setInterval(() => {
        const content = Swal.getHtmlContainer()



            if (index == 0) {

                  //INICIO ACTUAIZACION
                  try {
                    
                  //lenamos nuestro array

        

                  lines.forEach(element => {
                
                    //  s = txt.CreateTextFile("braulio90.txt", true);
                    //  s.WriteLine("Hello");
               

                    // console.log('LIMON: ')
          
                    if (element.split('|')[9] == 'ACTIVO') {
                    
                      // RncTemp =   element.split('|')[0].replace(/[abc]/g, data => replacesData[data]);
                      // Nombre_empresaTemp =   element.split('|')[1].replace(/[abc]/g, data => replacesData[data]);
                      // EstatusTemp =   element.split('|')[9].replace(/[abc]/g, data => replacesData[data]);
                      // TipoTemp =   element.split('|')[10].replace(/[abc]/g, data => replacesData[data]);

                      //  RncTemp = element.split('|')[0].replace({'`':' ','ø':'O'});
                      //  Nombre_empresaTemp = element.split('|')[1].replace({'`':' ','ø':'O'});
                      //  EstatusTemp = element.split('|')[9].replace({'`':' ','ø':'O'});
                      //  TipoTemp = element.split('|')[10].replace({'`':' ','ø':'O'});


                      //`
                       RncTemp = element.split('|')[0].replace(',', ' ');
                       Nombre_empresaTemp = element.split('|')[1].replace(',', ' ');
                       EstatusTemp = element.split('|')[9].replace(',', ' ');
                       TipoTemp = element.split('|')[10].replace(',', ' ');

                      limon +=  RncTemp  + "|"+ Nombre_empresaTemp
                       +"|"+EstatusTemp + "|"+ TipoTemp +"|"+"\n";
                      
                      //  RncTemp = element.split('|')[0].replace('"', ' ');
                      //  Nombre_empresaTemp = element.split('|')[1].replace('"', ' ');
                      //  EstatusTemp = element.split('|')[9].replace('"', ' ');
                      //  TipoTemp = element.split('|')[10].replace('"', ' ');

                      // limon += "('" + RncTemp + "', '"+ Nombre_empresaTemp +"', '"+
                      // EstatusTemp + "', '"+ TipoTemp +"'),\n";
  
                      // console.log(limon)
 
                    }

                      /*
                      esquemaTabla = {
                      Rnc: element.split('|')[0],
                      nombre_empresa: element.split('|')[1],
                      Estatus: element.split('|')[9],
                      Tipo: element.split('|')[10]
                    }
                     */

                  //  if (esquemaTabla.Estatus == 'ACTIVO') {

                  //     arrayTemp.push(esquemaTabla);
                  //   }
                   
           
                  });

                  //leyendo array

      // if (localStorage.getItem(this.myLocalStorage.clientes_RNC_storage)) {
      //    //borramos
      //    localStorage.removeItem(this.myLocalStorage.clientes_RNC_storage);
      //    localStorage.setItem(this.myLocalStorage.clientes_RNC_storage, JSON.stringify(arrayTemp));
      // }else{
      //   localStorage.setItem(this.myLocalStorage.clientes_RNC_storage, JSON.stringify(arrayTemp));
      // }


      // limon+=parte3PHP;
      var blob = new Blob([limon], {type: "text/plain;charset=utf-8"});
      saveAs(blob, "MY-DGII-RNC.txt");

/*
                //  s.Close();
                 var Rnc_to_Json = JSON.stringify(arrayTemp);
                //  console.log('BUUUUM!')
                  // console.log(Rnc_to_Json)

                      // DESPUES DE BORRAR LA TABLA INSERTAMOS
                            $.ajax({
                              type: "POST",
                              url: this.linkInsert_rnc_mysql,
                              data: {
                                data : Rnc_to_Json
                              }, 
                              cache: false,
                              success: function(){
                                console.log('RNC sincroniados!')
                              }
                          });
                          // fin  
      
*/

                  //FIN ACTUAIZACION

                  } catch (error) {
                    this.peticiones.Dialogo_Success_Error('¡Debes de seleccionar un archivo valido \nen formato .Txt!', 'info')
                    return  
                  }
                index =1;
              }
 



        if (content) {
 
          const b = content.querySelector('b')
          if (b) {
           
            // b.textContent = Swal.getTimerLeft()

}
}
}, 100)
},
willClose: () => {
clearInterval(timerInterval)
}
}).then((result) => {
/* Read more about handling dismissals below */
if (result.dismiss === Swal.DismissReason.timer) {
// this.peticiones.Dialogo_Success_Error('Listado Actualizado!', 'success');
this.modalController.dismiss();
}
})
//FIN DIALOGO..........
 
 }


    } catch (error) {
          this.peticiones.Dialogo_Success_Error('Debes de seleccionar un archivo valido \nen formato .Txt!', 'info')
    }
}



}
