import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import { Canvas, Cell, Img, Line, PdfMakeWrapper, QR, SVG, Table, TextReference, Txt } from 'pdfmake-wrapper';
import * as moment from 'moment';
import { FacturacionesService } from 'src/app/Providers/facturaciones.service';


@Component({
  selector: 'app-reimpresiones',
  templateUrl: './reimpresiones.page.html',
  styleUrls: ['./reimpresiones.page.scss'],
})
export class ReimpresionesPage implements OnInit {
  pdf = new PdfMakeWrapper();

  inputFiltrar:string;
  listadoVentasFijo:any[];
  listadoVentas:any[];

  listado_Detalles_Ventas:any[];
  

  filtroPor:string = 'GENERAL';
  fechaParaFactura:any = moment().format("DD-MM-YYYY hh:mm");

  //CABEZAL 1
  nombreEmpresa:string;
  rncEmpresa:string;
  fechaFactura:string;
  telefonoEmpresa:string;
 
  //CLIENTE 2
  rncCliente:string;
  cliente:string;
  noFactura:string;
  tipoNcf:string;
  ncfFactura:string;
  metotoPago:string;

  //TOTALES
  descuento:any;
  itbis:any;
  total:any;
  recibido:any;
  cambio:any;

  atendidoPor:any;
 


  constructor(public peticiones: PeticionesService, public alertController:AlertController,
    public facturacion: FacturacionesService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.GetAll_ventas();
  }



  public GetAll_ventas(){
    this.peticiones.GetAll_Ventas_Json().then( (data:any) => {
      if (data['Ventas']) {
          this.listadoVentas = data['Ventas'];
          this.listadoVentasFijo = data['Ventas'];
      }

    });
  } 

  public Filtrar(){

        var  listado_Filtrado:any;
        listado_Filtrado = this.listadoVentasFijo.filter(
        item => item.NoFactura.includes (this.inputFiltrar.toUpperCase()) ||
        item.Cliente.includes (this.inputFiltrar.toUpperCase()) || 
        item.NoComprobante.includes (this.inputFiltrar.toUpperCase()));
  
        this.listadoVentas = listado_Filtrado
        
      if (this.inputFiltrar == '') {
        this.GetAll_ventas();
      }

  }


  public Reimprimir(noFactura:any, Cliente:any, Fecha:any, TipoComprobante:any,
    NoComprobante:any, Descuento:any, Itbis:any, Total:any, metotoPago:string,
     atendidoPor:any, recibido:any, cambio:any, rncCliente:any){

      //llenamos nuestras variables
      this.noFactura = noFactura; this.cliente = Cliente;
      this.fechaParaFactura = Fecha; this.tipoNcf = TipoComprobante;
      this.ncfFactura = NoComprobante; this.descuento = Descuento;
      this.itbis = Itbis; this.total = Total;
      this.cambio = cambio;
      this.atendidoPor = atendidoPor;
      this.metotoPago = metotoPago;
      this.recibido = recibido;
      this.rncCliente = rncCliente;

      this.Get_detalles_ventas(noFactura);
  }

  public Get_detalles_ventas(noFactura:string){


  this.peticiones.GetAll_Detalles_Ventas_Json(noFactura).then( (data:any) => {
      if (data['Detalles_Ventas']) {
          this.listado_Detalles_Ventas = data['Detalles_Ventas'];

          // GENERAMOS LA FACTURA :)
          this.facturacion.PDF_factura_pequena(noFactura, this.listado_Detalles_Ventas,
            this.fechaParaFactura,this.tipoNcf, this.cliente, this.ncfFactura,
            this.itbis, this.total, this.atendidoPor, this.metotoPago, this.recibido, this.cambio, this.rncCliente );
            
      }else{
          this.listado_Detalles_Ventas = [];
      }
    });
    
  }





  // ONLY TESTING----FACTURA GRANDE!
public async generar_test_PDF(){

  const pdf = new PdfMakeWrapper();

 pdf.add(
        new Txt('SisBOA \n\n').bold().italics().end
      );


  pdf.add(
    new Table([
      ['Descripcion', 'Cant', 'Precio', 'Sub-Total'],
      [ 'COMPUTADORA', 3, 2000, 4000],
      [ 'LAPTOP', 2, 3000, 8000],
      [ 'TABLET', 12, 25000, 4000],
      [ 'MAUSE', 50, 4000, 400],
      [ 'FORRO PARA LAPTOP 15', 100, 600, 7000]
  ]).widths([250, 50, 50, 70])
  .layout({
    fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
        // return rowIndex %2 === 0 ? '#f1f3f4': '';
         return rowIndex  === 0 ? '#f1f3f4': '';
      }
 }).end
  )

//.widths([ '*', 100 ])

  pdf.create().open();
}






// convierte en formato de miles
public fomatNum = (number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  return number.toString().replace(exp,rep);
}
//genera un codigo QR de un enlace o etc...
public generar_codigo_QR(stringQR: string){
    this.pdf.add(new QR(stringQR).fit(100).end);
    // .fit(50)  small
    // .fit(100)  L

    this.pdf.create().open();
}




}
