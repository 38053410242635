import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';

@Component({
  selector: 'app-modal-create-new-cliente',
  templateUrl: './modal-create-new-cliente.page.html',
  styleUrls: ['./modal-create-new-cliente.page.scss'],
})
export class ModalCreateNewClientePage implements OnInit {

  @Input() codigoEDIT: string
  @Input() negocioEDIT: string
  @Input() direccionEDIT: string
  @Input() telefonoEDIT: string
  @Input() rncEDIT: string
  @Input() tipoPrecioEDIT: string
  @Input() activoEDIT: string
  @Input() permisoMostrarEDIT: string


  //Datos del cliente:
  codigoCliente:string;
  negocioCliente:string;
  direccionCliente:string;
  telefono:string;
  Rnc:string;
  TipoPrecio:string;


  listadoClientes_Temporal:any [];

  constructor(public modalController: ModalController, public alertController: AlertController,
    public peticiones: PeticionesService, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.GetClientes_LocalStorage();
  }

  public GetClientes_LocalStorage(){
    if(localStorage.getItem(this.myLocalStorage.listado_Clientes)){
      var ListadoTem_Storage = localStorage.getItem(this.myLocalStorage.listado_Clientes);
      this.listadoClientes_Temporal = JSON.parse(ListadoTem_Storage);


              // BUSCANDO CODIGO
              var ArrayNumber = [];
              this.listadoClientes_Temporal.forEach(element => {
                ArrayNumber.push(element['codigo']);
              });
      
              let numeroMayor = Math.max.apply(null,ArrayNumber);
              var codigoPreparado = '';
              
              if (numeroMayor != -Infinity) {
                  // '00'+ (numeroMayor+1)
                  codigoPreparado = '00'+ (numeroMayor+1);
      
              }else{    codigoPreparado = '001';
               }
              // FIN/ BUSCANDO CODIGO


              
      if (this.codigoEDIT) {
        this.codigoCliente = this.codigoEDIT;
        this.negocioCliente = this.negocioEDIT;
        this.direccionCliente = this.direccionEDIT;
        this.telefono = this.telefonoEDIT;
        this.Rnc = this.rncEDIT;
        this.TipoPrecio = this.tipoPrecioEDIT;

      }else{
        this.codigoCliente = codigoPreparado;
      }




    }
  }



  salir_con_parametro(codigo:string, negocio:string, direccion:string){
    this.modalController.dismiss({
      codigo: codigo,
      negocio: negocio,
      direccion: direccion,

    });
    }
    salir_sin_parametro(){
      this.modalController.dismiss();
      }


  public async dialogo_confirmar_guardar_cliente(guardar_Editar:string){

        if (this.negocioCliente) {
             const alertImagenProducto =  await  this.alertController.create({
    
          header:  'Desea '+guardar_Editar+' al cliente:',
          message:  this.negocioCliente+' ?',
          // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
          cssClass: 'my-custom-class',
  
        buttons: [
          {
            text: 'Cancelar',
            role: 'destructive',
            handler: (valor) => {
              // NADA
  
            }
          }, {
            role:'aceptar',
            text: guardar_Editar+' ✅',
            handler: (valor) => {
                  // aceptar

                  if(localStorage.getItem(this.myLocalStorage.listado_Clientes)){
                    if (guardar_Editar == 'editar') {
                      this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Clientes, 'codigo',this.codigoCliente);
                    }


                    var ListadoClientes_Storage = localStorage.getItem(this.myLocalStorage.listado_Clientes);
                    this.listadoClientes_Temporal = JSON.parse(ListadoClientes_Storage);

                    if (!this.direccionCliente) {
                      this.direccionCliente = '.';
                    }
                     this.listadoClientes_Temporal.push(
                      {
                      codigo: this.codigoCliente.toUpperCase(),
                      negocio: this.negocioCliente.toUpperCase(), 
                      direccion: this.direccionCliente.toUpperCase(),
                      telefono: this.telefono,
                      tipoPrecio: this.TipoPrecio,
                      activo: this.activoEDIT,
                      Rnc: this.Rnc,
                    });

                    localStorage.removeItem(this.myLocalStorage.listado_Clientes);
                    localStorage.setItem(this.myLocalStorage.listado_Clientes, JSON.stringify(this.listadoClientes_Temporal));
                  }

                  this.salir_con_parametro(this.codigoCliente, this.negocioCliente, this.direccionCliente)
            }
          }
        ]
          });
          alertImagenProducto.present()

        }//no existe nombre del cliente
        else{
          //si no exite un nombre:
        }
    }

  public guardarCliente(){

  }

    
  public async dialogo_select_tipo_precio(){

    const alertImagenProducto =  await  this.alertController.create({
  
      header:  'Tipo de Precio',
      message: 'Selecciona un tipo de precio',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',
  
    inputs:[
      {
      id: 'Uno',
      type: 'radio',
      name:'uno_',
      label:'1',
      value: '1',
    }, 
    {
      id: 'Dos',
      type: 'radio',
      name:'dos_',
      label:'2',
      value: '2',
    }, 
    {
      id: 'Tres',
      type: 'radio',
      name:'tres_',
      label:'3',
      value: '3',
    }],
  
    buttons: [
      {
        role:'destructive',
        text: 'Cancelar',
        handler: (valor) => {
                // cancelar
  
        }
      },
       {
        role:'aceptar',
        text: 'ACEPTAR',
        handler: (valor) => {
                // OK
                if (valor == '1') {
                    this.TipoPrecio = '1';
                }if (valor == '2') {
                     this.TipoPrecio = '2';
                }
                if (valor == '3') {
                  this.TipoPrecio = '3';
             }
  

        }
      }
    ]
      });
      alertImagenProducto.present()
    }





}
