import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import { ModalAddProductoPage } from '../modal-add-producto/modal-add-producto.page';
import { ModalCreateNewClientePageModule } from './modal-create-new-cliente/modal-create-new-cliente.module';
import { ModalCreateNewClientePage } from './modal-create-new-cliente/modal-create-new-cliente.page';

@Component({
  selector: 'app-modal-buscar-cliente',
  templateUrl: './modal-buscar-cliente.page.html',
  styleUrls: ['./modal-buscar-cliente.page.scss'],
})
export class ModalBuscarClientePage implements OnInit {

  inputFiltrar:string;
  public listadoClientes:any[];

  constructor(public peticiones: PeticionesService, public modalController: ModalController,
    public alertController: AlertController, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.GetClientes_LocalStorage();
  }


  salir_con_parametro(codigo:string, negocio:string, direccion:string, telefono:string){
    this.modalController.dismiss({
      codigo: codigo,
      negocio: negocio,
      direccion: direccion,
      telefono: telefono


    });
    }
    salir_sin_parametro(){
      this.modalController.dismiss();
      }

      
      // CREANDO NUEVO CLIENTE
      public async openNuevoCliente(){
          // Creando nuevo Cliente
    try {
          const modal = await this.modalController.create({
            component: ModalCreateNewClientePage,
            cssClass: 'my-custom-class',
            componentProps: {
              'cliente': ''},
          });
          await modal.present();
          //para recibir datos
        try {
              const {data} = await modal.onDidDismiss()
              if(data){
                //  console.log('Direccion: '+data.direccion + '\n Codigo'+ data.codigo + '\n Negocio' +data.negocio)
              this.GetClientes_LocalStorage();
            }else{
                // this.MostrarPedidosJson();
              }
          } catch (error) {
            console.log('error al leer datos no existe!')
          }
            
    } catch (error) {
          // this.peticiones.Dialogo_Error_Condiciones('No Tienes Un Pedido Seleccionado');
    }
  
      }


  
    public async dialogo_confirmar_cliente(Codigo:string, Negocio:string, Direccion:string, Telefono:string, accionEjecutar:string){

      if (accionEjecutar == 'elegirCliente') {
        this.modalController.dismiss({
          codigo: Codigo,
          negocio: Negocio,
          direccion: Direccion,
          telefono: Telefono
    
        });
      }


      /*
      const alertImagenProducto =  await  this.alertController.create({
  
        header:  'Desea seleccionar al cliente:',
        message:  Negocio+' ?',
        // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
        cssClass: 'my-custom-class',

      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA

          }
        }, {
          role:'aceptar',
          text: 'Seleccionar',
          handler: (valor) => {

            // ✅
                // aceptar

                if (accionEjecutar == 'elegirCliente') {
                  this.modalController.dismiss({
                    codigo: Codigo,
                    negocio: Negocio,
                    direccion: Direccion,
              
                  });
                }


              // this.salir_con_parametro(Codigo, Negocio, Direccion);

          }
        }
      ]
        });
        alertImagenProducto.present()
     */
     
      }
    
    

  public FiltrarClientess (){
    if(localStorage.getItem(this.myLocalStorage.listado_Clientes)){
      //console.log('Si existen clientes')

      var ListadoClientes_Storage = localStorage.getItem(this.myLocalStorage.listado_Clientes);
      let listadoClientesTem:any = JSON.parse(ListadoClientes_Storage);
      //console.log(listadoClientesTem) 

      let clienteFiltrado = listadoClientesTem.filter(
      cliente => cliente.codigo.includes (this.inputFiltrar.toUpperCase()) ||
      cliente.negocio.includes (this.inputFiltrar.toUpperCase()) ||
      cliente.direccion.includes (this.inputFiltrar.toUpperCase()));
      this.listadoClientes = clienteFiltrado

    }else{
      console.log('No existen clientes')
    }
  }


  public Sincronizar_Clientes_Json(){
        this.peticiones.obtenerClientesJson().then((data:any) =>{
            if (data[this.myLocalStorage.listado_Clientes]) {
              if(localStorage.getItem(this.myLocalStorage.listado_Clientes)){
                localStorage.removeItem(this.myLocalStorage.listado_Clientes);
                localStorage.setItem(this.myLocalStorage.listado_Clientes, JSON.stringify(data['Clientes_Ionic']));

                this.GetClientes_LocalStorage();
              }else{
                localStorage.setItem(this.myLocalStorage.listado_Clientes, JSON.stringify(data['Clientes_Ionic']));
                this.GetClientes_LocalStorage();
              }
              console.log('REGISTRO EXITOSO!')
            }
          })
  }


  public GetClientes_LocalStorage(){
    if(localStorage.getItem(this.myLocalStorage.listado_Clientes)){
      var ListadoClientes_Storage = localStorage.getItem(this.myLocalStorage.listado_Clientes);
      this.listadoClientes = JSON.parse(ListadoClientes_Storage);
      //console.log(this.listadoClientes)
    }
  }

  


}
