import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Canvas, Cell, Img, Line, PdfMakeWrapper, QR, SVG, Table, TextReference, Txt } from 'pdfmake-wrapper';
import { EmpresaService } from './empresa.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportesSService {
  pdf = new PdfMakeWrapper();
  fecha_actual:any = moment().format('DD-MM-YYYY');

  constructor(public miEmpresa: EmpresaService) { }


    // VENTAS POR FECHAS
    public async PDF_ventas_por_fechas(nombreTabla:any, fechaDesde:any, 
                                                    fechaHasta:any,  arrayTemp:any[]){
    
    console.log('DENTRO DE REPORTE!')
        const pdf = new PdfMakeWrapper();


        var mostrar_fechas_Venta:any = '';
        if (fechaDesde && fechaHasta) {
          mostrar_fechas_Venta = 'DESDE: '+fechaDesde +' ' +' HASTA: '+fechaHasta+'\n\n'
        }else{
          mostrar_fechas_Venta = '';
        } 

          // TITULO
          pdf.add(
                  new Txt(this.miEmpresa.datosEmpresa.nombre+'\n\n'+
                  'REPORTE DE:  '+nombreTabla +'\n'+mostrar_fechas_Venta +'\n'+
                  'FECHA: '+this.fecha_actual +'\n\n')
                  .alignment('center')
                  .bold()
                  .italics().end
                );

          // CABEZAL TOTALES
          pdf.add( 
            new Txt('Total-Itbis: '+this.get_totales_del_array(arrayTemp).itbis+'\n'+
                    'Total-Descuento: '+this.get_totales_del_array(arrayTemp).descuento+'\n'+
                    'Total:  '+this.get_totales_del_array(arrayTemp).total+'\n')
            .alignment('left')
            .bold()
            .italics().end
          );
          // TOTAL DE VENTAS REALIZADAS
          pdf.add(      
            new Txt('Ventas: '+arrayTemp.length+'\n\n')
            .alignment('center')
            .bold()
            .italics().end
          );




          //1 CABEZAL TABLA DETALLES
          pdf.add(
            new Table([
              ['No.FACTURA', 'CLIENTE', 'NCF', 'DESC %', 'ITBIS', 'TOTAL', 'FECHA'],
              
          ])
          .color('#F9F9F9')
          .fontSize(10)
          .bold()
          .widths([72, 100, 80, 63, 45, 55, 55])
          .layout({
            fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
                // return rowIndex %2 === 0 ? '#f1f3f4': '';
                return rowIndex  === 0 ? '#39A6A3': '';
              }
        }).end
          )
          //2 TABLA DETALLES MASIVOS
          arrayTemp.forEach(element => {
            pdf.add(
              new Table([
                [ element.NoFactura, element.Cliente, element.NoComprobante.substr(8) , element.Descuento, 
                  element.Itbis, element.Total, element.Fecha]
                
                ])
                .fontSize(10)
                .widths([72, 100, 80, 63, 45, 55, 55])
                .layout({
              fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
                  // return rowIndex %2 === 0 ? '#f1f3f4': '';
                  return rowIndex  === 0 ? '': '';
                }
          }).end
            )


          })




                //FIN
          pdf.create().open();

      }


    public async PDF_clientes(nombreTabla:any, arrayTemp:any[]){
    
        const pdf = new PdfMakeWrapper();
    
        // TITULO
        pdf.add(
          new Txt(this.miEmpresa.datosEmpresa.nombre+'\n\n'+
          'REPORTE DE:  '+nombreTabla+'\n'+
          'FECHA: '+this.fecha_actual +'\n\n')
          .alignment('center')
          .bold()
          .italics().end
        );
        
        // TOTAL DE CLIENTES
        pdf.add(      
          new Txt('Total: '+arrayTemp.length+'\n\n')
          .alignment('center')
          .bold()
          .italics().end
        );


        //1 CABEZAL TABLA DETALLES
        pdf.add(
          new Table([
            ['CODIGO', 'CLIENTE', 'DIRECCION', 'TELEFONO', 'RNC'],
            
        ])
        .color('#F9F9F9')
        .fontSize(12)
        .bold()
        .widths([75, 100, 100, 75, 65])
        .layout({
          fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
              // return rowIndex %2 === 0 ? '#f1f3f4': '';
              return rowIndex  === 0 ? '#125D98': '';
            }
      }).end
        )
        //2 TABLA DETALLES MASIVOS
        arrayTemp.forEach(element => {
          pdf.add(
            new Table([

              [ element.codigo, element.negocio, element.direccion , element.telefono, element.Rnc]
              ])
              .fontSize(10)
              .widths([75, 100, 100, 75, 65])
              .layout({
            fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
                // return rowIndex %2 === 0 ? '#f1f3f4': '';
                return rowIndex  === 0 ? '': '';
              }
        }).end
          )


        })






        
          pdf.create().open();

      }
    public async PDF_suplidores(nombreTabla:any, arrayTemp:any[]){
  
        const pdf = new PdfMakeWrapper();
    
        // TITULO
        pdf.add(
          new Txt(this.miEmpresa.datosEmpresa.nombre+'\n\n'+
          'REPORTE DE:  '+nombreTabla +'\n'+
          'FECHA: '+this.fecha_actual +'\n\n')
          .alignment('center')
          .bold()
          .italics().end
        );
        

        //TOTAL DE SUPLIDORES
        pdf.add(      
          new Txt('Total: '+arrayTemp.length+'\n\n')
          .alignment('center')
          .bold()
          .italics().end
        );

        //1 CABEZAL TABLA DETALLES
        pdf.add(
          new Table([
            ['CODIGO', 'SUPLIDOR', 'DIRECCION', 'TELEFONO', 'RNC'],
            
        ])
        .color('#F9F9F9')
        .fontSize(12)
        .bold()
        .widths([75, 100, 100, 75, 65])
        .layout({
          fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
              // return rowIndex %2 === 0 ? '#f1f3f4': '';
              return rowIndex  === 0 ? '#34656D': '';
            }
      })
  
      .end
        )
        //2 TABLA DETALLES MASIVOS
        arrayTemp.forEach(element => {
          pdf.add(
            new Table([

              [ element.Codigo, element.Proveedor, element.direccion , element.telefono, element.rnc]
              ])
              .fontSize(10)
              .widths([75, 100, 100, 75, 65])
              .layout({
            fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
                // return rowIndex %2 === 0 ? '#f1f3f4': '';
                return rowIndex  === 0 ? '': '';
              }
        }).end
          )


        })






        
          pdf.create().open();

      }
    public async PDF_productos(nombreTabla:any, arrayTemp:any[]){

      const pdf = new PdfMakeWrapper();
  
      // TITULO
      pdf.add(
        new Txt(this.miEmpresa.datosEmpresa.nombre+'\n\n'+
        'REPORTE DE:  '+nombreTabla +'\n'+
        'FECHA: '+this.fecha_actual +'\n\n')
        .alignment('center')
        .bold()
        .italics().end
      );
      

      //TOTAL DE PRODUCTOS
      pdf.add(      
        new Txt('Total: '+arrayTemp.length+'\n\n')
        .alignment('center')
        .bold()
        .italics().end
      );

      //1 CABEZAL TABLA DETALLES
      pdf.add(
        new Table([
          ['CODIGO', 'DESCRIPCIÓN', 'REFERENCIA', 'STOCK', 'PRECIO', 'SUPLIDOR', 'MODIFICADO'],
          
      ])
      .color('#F9F9F9')
      .fontSize(10)
      .bold()
      .widths([50, 100, 70, 40, 55, 60, 65])
      .layout({
        fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
            // return rowIndex %2 === 0 ? '#f1f3f4': '';
            return rowIndex  === 0 ? '#590995': '';
          }
    })

    .end
      )
      //2 TABLA DETALLES MASIVOS
      arrayTemp.forEach(element => {
        pdf.add(
          new Table([

            [ element.codigo, element.descripcion, element.referencia , element.stock, element.precio,
            element.proveedor,element.ultimaModificacion]
            ])
            .fontSize(10)
            .widths([50, 100, 70, 40, 55, 60, 65])
            .layout({
          fillColor: (rowIndex:number, node:any, columnIndex:number)=>{
              // return rowIndex %2 === 0 ? '#f1f3f4': '';
              return rowIndex  === 0 ? '': '';
            }
      }).end
        )


      })






      
        pdf.create().open();

    }



    //OBTENER TOTALES DE:   ITBIS, DESC, TOTAL
    get_totales_del_array(arrayTemp:any[]){

      var total_desc:number = 0;
      var total_ITBIS:number = 0;
      var total_General:number = 0;


    //recorremos
    arrayTemp.forEach(element => {
      total_desc +=  parseFloat(element["Descuento"]);
      total_ITBIS +=  parseFloat(element["Itbis"]);
      total_General +=  parseFloat(element["Total"]);
    });


    // Llenamos
      var totales = {
        itbis: this.fomatNum(total_ITBIS.toFixed(2)),
        descuento: this.fomatNum(total_desc.toFixed(2)),
        total: this.fomatNum(total_General.toFixed(2))
      } 


    //Retornamos
      return totales;
    }

    // FORMATO DE MILES CON 2 DECIMALES
    fomatNum = (number:any) => {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      return number.toString().replace(exp,rep);
    }




}
