import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstoragesService {

  constructor() { }

  //MI BASE DE DATOS-   LOCAL-STORAGE

 public listado_Clientes:string = 'listadoClientes';
 public listado_Productos:string = 'listadoProductos';
 public listado_Departamentos:string = 'listadoDepartamentos';
 public listado_Categorias:string = 'listadoCategoria';
 public listado_Proveedores:string = 'listadoProveedores';

 public Venta_Temporal:string = 'Detalle_ventaTemporar';
 public Ventas_Agendadas:string = 'Ventas_Agendadas';
 public Comprobantes_Fiscales_Storage:string = 'ComprobantesFiscales';
 public clientes_RNC_storage:string = 'ListaClientesRnc';
 
// convierte en formato de miles
public fomatNum = (number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  return number.toString().replace(exp,rep);
}




}
