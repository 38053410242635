import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { PeticionesService } from 'src/app/Providers/peticiones.service';

@Component({
  selector: 'app-crearnuevo-departamento',
  templateUrl: './crearnuevo-departamento.page.html',
  styleUrls: ['./crearnuevo-departamento.page.scss'],
})
export class CrearnuevoDepartamentoPage implements OnInit {

  @Input() codigoEDIT: string
  @Input() departamentoEDIT: string
  @Input() permisoEditarDepartamento: string


  //Datos departamentos
  codigo_AUTO:string;
  Departamento:string;

  constructor(public peticiones: PeticionesService, public modalController: ModalController,
    public alertController: AlertController, public myLocalStorage: LocalstoragesService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.GetDepartamento_LocalStorage();
  }

  public GetDepartamento_LocalStorage(){
    if(localStorage.getItem(this.myLocalStorage.listado_Departamentos)){
      var ListadoTem_Storage = localStorage.getItem(this.myLocalStorage.listado_Departamentos);
      let listado_Temporal = JSON.parse(ListadoTem_Storage);


              // BUSCANDO CODIGO
              var ArrayNumber = [];
              listado_Temporal.forEach(element => {
                ArrayNumber.push(element['Codigo']);
              });
      
              let numeroMayor = Math.max.apply(null,ArrayNumber);
              var codigoPreparado = '';
              
              if (numeroMayor != -Infinity) {
                  // '00'+ (numeroMayor+1)
                  codigoPreparado = '00'+ (numeroMayor+1);
      
              }else{    codigoPreparado = '001';
               }
      

               




      if (this.codigoEDIT) {
        this.codigo_AUTO = this.codigoEDIT;
        this.Departamento = this.departamentoEDIT;
      }else{
        this.codigo_AUTO = codigoPreparado;
      }
    }
  }

  salir_sin_parametro(){
    this.modalController.dismiss();
    }

  public async dialogo_confirmar_guardar_departamento(guardar_Editar:string){
    if (this.Departamento) {
    const alertImagenProducto =  await  this.alertController.create({
      header:  'Desea '+guardar_Editar+' el departamento:',
      message:  this.Departamento+' ?',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',

    buttons: [
      {
        text: 'Cancelar',
        role: 'destructive',
        handler: (valor) => {
          // NADA

        }
      }, {
        role:'aceptar',
        text: guardar_Editar+' ✅',
        handler: (valor) => {
              // aceptar
              if(localStorage.getItem(this.myLocalStorage.listado_Departamentos)){

                if (guardar_Editar == 'editar') {
                  this.peticiones.Eliminar_From_Array(this.myLocalStorage.listado_Departamentos, 'Codigo',this.codigo_AUTO);
                }

                var ListadoTemp_Storage = localStorage.getItem(this.myLocalStorage.listado_Departamentos);
                let listado_Temporal:any = JSON.parse(ListadoTemp_Storage);

                listado_Temporal.push(
                  {
                  Codigo: this.codigo_AUTO.toUpperCase(),
                  Departamento: this.Departamento.toUpperCase(),
                });
                localStorage.removeItem(this.myLocalStorage.listado_Departamentos);
                localStorage.setItem(this.myLocalStorage.listado_Departamentos, JSON.stringify(listado_Temporal));
                this.modalController.dismiss({
                  departamentos: 'exitoso!'
                });
              }
        }
      }
    ]
      });
      alertImagenProducto.present()

    }//no existe nombre del cliente
    else{
      //si no exite un nombre:
    }
    }



}
