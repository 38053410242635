import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { PeticionesService } from 'src/app/Providers/peticiones.service';
import { ModalBuscarClientePage } from '../modal-buscar-cliente/modal-buscar-cliente.page';
import { NetworkStatus, PluginListenerHandle, Plugins } from '@capacitor/core';
import { LocalstoragesService } from 'src/app/Providers/localstorages.service';
import { FacturacionesService } from 'src/app/Providers/facturaciones.service';
import { ComprobantesService } from 'src/app/Providers/comprobantes.service';
import { LoginServiceService } from 'src/app/Providers/login-service.service';


const { Network } = Plugins;

@Component({
  selector: 'app-completando-pago',
  templateUrl: './completando-pago.page.html',
  styleUrls: ['./completando-pago.page.scss'],
})


export class CompletandoPagoPage implements OnInit {

  @Input() CodigoCliente: string;
  @Input() cliente: string;
  @Input() rncCliente: string;
  @Input() DireccionCliente: string;
  @Input() TelefonoCliente: string;


  modoDePago: string = 'EFECTIVO';
  tipoComprobante: string = 'CONSUMIDOR FINAL';//PENDIENTE
  noComprobante: string = '00000000';//PENDIENTE
  fechaParaFactura: any = moment().format("DD-MM-YYYY hh:mm");;

  totalEfectivo: any;
  totalTarjeta: any;
  noConfirmacionTarjeta: string;

  totalRecibido: any;
  diferenciaTotales: any;

  @Input() totalDescuento: any
  @Input() totalItbis: any
  @Input() totalpagar: any

  hayInternet: boolean = false;
  cedulaRNC:any = '';
  constructor(public alertController: AlertController, public modalController: ModalController,
    public peticiones: PeticionesService, public myLocalStorage: LocalstoragesService,
    public facturacion: FacturacionesService, public comprobantesS: ComprobantesService,
    public login: LoginServiceService) {
    // this.fechaActual = new Date();
  }

  ionViewWillEnter() {
    this.getFecha();
    this.sumarTotales();
    this.cedulaRNC='';
  }


  salir_sin_parametro() {
    this.modalController.dismiss();
  }


  // COMPROBAR INTERNET _______________________________________________
  public networkStatus: NetworkStatus;
  public networkListener: PluginListenerHandle;
  async ngOnInit() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      console.log("Network status changed", status);
      this.networkStatus = status;
    });
    this.networkStatus = await Network.getStatus();
  }

  ngOnDestroy() {
    this.networkListener.remove();
  }
  public getTest(){
    console.log()
  }

  public async Existe_Conexion_Internet() {

    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      console.log("Network status changed", status);
      this.networkStatus = status;
    })

    if (this.networkStatus.connected) {
      // Hay internet
      try {
        if (this.totalTarjeta > 0 && this.noConfirmacionTarjeta.length < 5) {
          this.peticiones.Dialogo_Success_Error('Debes completar el No. Autorización', 'info');
          return
        }
      } catch (error) {
        this.peticiones.Dialogo_Success_Error('Debes completar el No. Autorización', 'info');
        return
      }
      this.Finalizar_Factura_Mysql();
    }
    else {

      this.peticiones.Dialogo_Success_Error('No tienes conexion a internet!', 'error');
      // No Hay internet
      this.hayInternet = false;

    }

  }
  // FIN COMPROBAR INTERNET _______________________________________________




  public async dialogo_comprobante() {

    const alertImagenProducto = await this.alertController.create({

      header: 'Tipo de Comprobante',
      // message: referencia+'<br>PRECIO: RD$ '+precio+'<br> <img alt="foto" class="my-custom-class" src="'+imagenURL+'">',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',

      inputs: [
        {
          label: 'Consumidor',
          type: 'radio',
          value: 'CONSUMIDOR',
        },
        {
          label: 'Credito Fiscal',
          type: 'radio',
          value: 'CREDITO FISCAL',
        },
        {
          label: 'Regimen Especial',
          type: 'radio',
          value: 'REGIMEN ESPECIAL',
        },
        {
          label: 'Gubernamental',
          type: 'radio',
          value: 'GUBERNAMENTAL'
        },
        {
          label: 'Nota de Credito',
          type: 'radio',
          value: 'NOTA DE CREDITO',
        },
        {
          label: 'Nota de Debito',
          type: 'radio',
          value: 'NOTA DE DEBITO',
        },
        {
          label: 'Proveedores Informales',
          type: 'radio',
          value: 'PROVEEDORES INFORMALES',
        },
        {
          label: 'Gastos Menores',
          type: 'radio',
          value: 'GASTOS MENORES'
        }

      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA

          }
        }, {
          role: 'aceptar',
          text: 'Elegir',
          handler: (valor) => {
            // aceptar

            if (valor) {
              if (valor == 'CONSUMIDOR') {
                this.tipoComprobante = 'CONSUMIDOR FINAL';
                this.cedulaRNC = '';
                this.rncCliente = '';
                this.cliente = 'CONSUMIDOR FINAL';
              } else {
                this.tipoComprobante = valor;
              }

            }



          }
        }
      ]




    });
    alertImagenProducto.present()
  }
  public async dialogo_modo_pago() {

    const alertImagenProducto = await this.alertController.create({

      header: 'Método de Pago',
      // message: referencia+'<br>PRECIO: RD$ '+precio+'<br> <img alt="foto" class="my-custom-class" src="'+imagenURL+'">',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',



      inputs: [
        {
          label: 'Efectivo',
          id: 'txtEfectivo',
          type: 'radio',
          name: 'txtEfectivo',
          value: 'EFECTIVO',

        },
        {
          label: 'Tarjeta',
          id: 'txtTarjeta',
          type: 'radio',
          name: 'txtTarjeta',
          value: 'TARJETA',
        },
        {
          label: 'Efectivo y Tarjeta',
          id: 'txtTarjeta',
          type: 'radio',
          name: 'txtEfectivoYtarjeta',
          value: 'EFECTIVO Y TARJETA',

        }

      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA

          }
        }, {
          role: 'aceptar',
          text: 'Aceptar',
          handler: (valor) => {
            // aceptar

            if (valor) {
              this.modoDePago = valor;

              if (this.modoDePago == 'EFECTIVO') {
                this.totalTarjeta = 0;
                this.noConfirmacionTarjeta = '';
              }
              if (this.modoDePago == 'TARJETA') {
                this.totalEfectivo = 0;
                this.totalTarjeta = this.totalpagar;
              }

              this.sumarTotales();
            }

          }
        }
      ]




    });
    alertImagenProducto.present()
  }

  getFecha() {
    // this.fechaActual = moment().format("DD-MM-YYYY");
    this.fechaParaFactura = moment().format("DD-MM-YYYY hh:mm");
    //  let fecha_haceTiempo = moment("01-03-2021", "DD-MM-YYYY HH:MM:SS").fromNow();
    // alert(this.fechaParaFactura)
  }



  public sumarTotales() {
    if (!this.totalEfectivo) {
      this.totalEfectivo = 0;
    }
    if (!this.totalTarjeta) {
      this.totalTarjeta = 0;
    }


    this.totalRecibido = (this.totalEfectivo + this.totalTarjeta);
    this.diferenciaTotales = this.totalRecibido - (parseFloat(this.totalpagar));

  }
  accesoRapido_pago(valor: number, radical: string) {

    if (radical == 'completo') {
      if (this.modoDePago == 'EFECTIVO') {
        this.totalEfectivo = 0;
        this.totalEfectivo = valor;
      } else if (this.modoDePago == 'TARJETA') {
        this.totalEfectivo = 0;
        this.totalTarjeta = valor;
      }
    } else {

      if (this.modoDePago == 'EFECTIVO') {
        this.totalEfectivo += valor;
      } else if (this.modoDePago == 'TARJETA') {
        this.totalTarjeta += valor;
      } else if (this.modoDePago == 'EFECTIVO Y TARJETA') {
        this.totalEfectivo += valor;
        this.totalTarjeta += valor;
      }

    }


    this.sumarTotales();
  }




  // Buscar venta agendada
  public async cambiar_cliente() {
    try {
      const modal = await this.modalController.create({
        component: ModalBuscarClientePage,
        cssClass: 'my-custom-class',
        componentProps: {
          'cliente': ''
        },
      });
      await modal.present();
      //para recibir datos
      try {
        const { data } = await modal.onDidDismiss()
        if (data) {
          //console.log('Cliente: '+data.cliente)
          this.cliente = data.negocio;
          this.CodigoCliente = data.codigo;
          this.DireccionCliente = data.direccion;
          this.TelefonoCliente = data.telefono;


        } else {
          // this.MostrarPedidosJson();
        }
      } catch (error) {
        console.log('error al leer datos no existe!')
      }

    } catch (error) {
      // this.peticiones.Dialogo_Error_Condiciones('No Tienes Un Pedido Seleccionado');
    }
  }
  //finalizar
  public async dialogo_confirmar_finalizar() {

    const alertImagenProducto = await this.alertController.create({

      header: 'Finalizar Venta?',
      // message: referencia+'<br>PRECIO: RD$ '+precio+'<br> <img alt="foto" class="my-custom-class" src="'+imagenURL+'">',
      // message: `<img src=`+ imagenURL +`  style = "width: 700px;">`,      
      cssClass: 'my-custom-class',

      buttons: [
        {
          text: 'Cancelar',
          role: 'destructive',
          handler: (valor) => {
            // NADA

          }
        }, {
          role: 'aceptar',
          text: 'FINALIZAR',
          handler: (valor) => {
            // aceptar
            this.Existe_Conexion_Internet();


          }
        }
      ]




    });
    alertImagenProducto.present()
  }



  increment_NCF(tipoComprobante: string) {

    var nombreComprobante = '';
    switch (tipoComprobante) {

      case 'CONSUMIDOR FINAL':
        nombreComprobante = 'consumidor';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().consumidorFinal_ParteFija;
        break;

      case 'CREDITO FISCAL':
        nombreComprobante = 'creditoFiscal';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().CreditoFiscal_ParteFija;
        break;

      case 'REGIMEN ESPECIAL':
        nombreComprobante = 'regimenEspecial';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().RegimenEspeial_ParteFija;
        break;

      case 'GUBERNAMENTAL':
        nombreComprobante = 'gubernamental';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().Gubernamentales_ParteFija;
        break;


// menos comunes
      case 'NOTA DE CREDITO':
        nombreComprobante = 'notaCredito';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().NotaCredito_ParteFija;
        break;

      case 'NOTA DE DEBITO':
        nombreComprobante = 'notaDebito';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().NotaDebito_ParteFija;
        break;

      case 'PROVEEDORES INFORMALES':
        nombreComprobante = 'proveedoresInformales';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().ProveedoresInformales_ParteFija;
        break;

      case 'GASTOS MENORES':
        nombreComprobante = 'gastosMenores';
        this.noComprobante = this.comprobantesS.NCF_From_LocalStorage().GastosMenores_ParteFija;
        break;

      default:
        break;
    }
    this.comprobantesS.Increment_NCF_Json(nombreComprobante).then((data: any) => {
      this.get_Ultimo_NCF(nombreComprobante);
    });
  }
  get_Ultimo_NCF(nombreComprobante: string) {
    this.comprobantesS.Get_Ultimo_NCF_Json(nombreComprobante).then((data: any) => {
      if (data['ultimo_NCF']) {
         this.noComprobante += 
         this.comprobantesS.autocompletar_PadLeft('00000000',data['ultimo_NCF'][0].noNCF,'l');
      }
    });
  }

  consultar_cliente_con_RNC(){
try {
    this.comprobantesS.Get_Cliente_Con_RNC_Json(this.cedulaRNC).then((data:any)=>{
            if (data['cliente']) {

              this.rncCliente = data['cliente'][0].Rnc;
              this.cliente = data['cliente'][0].nombre_empresa;

                    // console.log(data['cliente'])
                    // console.log('RNC: ' +data['cliente'][0].Rnc)
                    // console.log('EMPRESA: ' +data['cliente'][0].nombre_empresa)
                    // console.log('TIPO: ' +data['cliente'][0].Tipo)
                    this.peticiones.Dialogo_exitoso('');
            }else{
              //alert('CLIENTE NO EXISTE!')
              this.peticiones.Dialogo_Success_Error('Este RNC no es valido!','info');

              this.cedulaRNC = '';
              this.cliente= 'CONSUMIDOR FINAL';
              this.rncCliente ='';
            }
    });
  } catch (error) {
    this.peticiones.Dialogo_Success_Error('El numero de cédula o rnc \ndebe tener mas de 8 caracteres!','error');
     return
  }

  
  }

              //FINALIZAR
  public async Finalizar_Factura_Mysql() {
    //this.getFecha();
    if (!this.noConfirmacionTarjeta || this.noConfirmacionTarjeta == undefined) {
      this.noConfirmacionTarjeta = '0';
    }

    
    if (this.tipoComprobante != "CONSUMIDOR FINAL") {
      if (this.rncCliente == '' || this.rncCliente == '0' || this.cliente == 'CONSUMIDOR FINAL')  {


        this.peticiones.Dialogo_Success_Error('Debes completar los datos del comprobante!','info');
        return
        
      }
    }



    // INCREMENTAMOS No.COMPROBANTE
    this.increment_NCF(this.tipoComprobante);
    
    //insertamos No.Factura from mysql
    this.peticiones.Insertar_NoFacturaJson().then((data) => {

      if (data) {
        //obtenemos No.Factura from mysql
        this.peticiones.Obtener_NoFacturaJson().then((data) => {
          if (data['NoFactura'][0].NoFactura) {

            var cambioTemp: any = (this.totalRecibido - this.totalpagar);

            if (this.rncCliente == '') {
              this.rncCliente = '0';
            }

            // No.Factura existe :)
            let NoFactura: any = data['NoFactura'][0].NoFactura;
            this.peticiones.Insert_Cabezal_Venta_Json(NoFactura, this.modoDePago, this.tipoComprobante,
              this.CodigoCliente, this.cliente, this.DireccionCliente, this.TelefonoCliente,
              this.noComprobante, this.totalDescuento, this.totalItbis, this.totalpagar,
              this.noConfirmacionTarjeta, ' CAJERO', cambioTemp, this.totalRecibido, this.rncCliente).then((data) => {

                if (data) {
                  // cabezal exitoso :) => entonces insertamos los detalles
                  this.fechaParaFactura = moment().format("DD-MM-YYYY hh:mm");
                  this.peticiones.Insertar_Detalles_Json(NoFactura, this.fechaParaFactura,
                    this.tipoComprobante, this.cliente, this.noComprobante,
                    this.totalItbis, this.totalpagar, this.login.usuarioLogueado.nombre, this.modoDePago,
                    this.totalRecibido, cambioTemp, this.rncCliente);

                    this.modalController.dismiss({cerrar:'recarga'});
                }
              });




          } else {
            this.peticiones.Dialogo_Success_Error('No tienes conexion a internet!', 'error')
          }

        })
      }
    })


  }

}
