import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalCreateNewClientePageRoutingModule } from './modal-create-new-cliente-routing.module';

import { ModalCreateNewClientePage } from './modal-create-new-cliente.page';
import { ModalAddProductoPage } from '../../modal-add-producto/modal-add-producto.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalCreateNewClientePageRoutingModule
  ],

  declarations: [ModalCreateNewClientePage]
})
export class ModalCreateNewClientePageModule {}
