import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CompletandoPagoPageRoutingModule } from './completando-pago-routing.module';

import { CompletandoPagoPage } from './completando-pago.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    
    ComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    CompletandoPagoPageRoutingModule
  ],
  declarations: [CompletandoPagoPage]
})
export class CompletandoPagoPageModule {}
