import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ServiceAjaxService } from './service-ajax.service';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { LocalstoragesService } from './localstorages.service';
import { ReturnStatement } from '@angular/compiler';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesService {

  descargar_listado_rnc:string;
  get_all_comprobantes_Json:string;


  update_consumidor_json:string;
  update_credito_fiscal_json:string;
  update_regimen_especial_json:string;
  update_gubernamental_json:string;
  //menos comunes
  update_nota_credito_json:string;
  update_nota_debito_json:string;
  update_proveedores_informales_json:string;
  update_gastos_menores_json:string;


  increment_NCF_json:string;
  get_ultimo_ncf_json:string;
  get_ultima_secuencia_json:string;

  listado_clientes_RNC:string;

  constructor(public http: HttpClient, public navCont: NavController, 
              public router: Router, public serviceAjax: ServiceAjaxService,
              public my_local_storage: LocalstoragesService, private browser: InAppBrowser) {


    this.descargar_listado_rnc = 'https://www.dgii.gov.do/app/WebApps/Consultas/RNC/DGII_RNC.zip';
    // get
    this.get_all_comprobantes_Json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/obtener_Comprobantes.php';
    //get ultimo
    this.get_ultimo_ncf_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/get_ultimo_NCF.php';
    //get ultima secuencia
    this.get_ultima_secuencia_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/get_ultima_secuencia.php';


    //updates
    this.update_consumidor_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/consumidor_final.php';
    this.update_credito_fiscal_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/credito_fiscal.php';
    this.update_regimen_especial_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/regimen_especial.php';
    this.update_gubernamental_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/gubernamental.php';
    this.update_nota_credito_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/nota_credito.php';
    this.update_nota_debito_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/nota_debito.php';
    this.update_proveedores_informales_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/proveedores_infirmales.php';
    this.update_gastos_menores_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Actualizar/gastos_menores.php';
    
    //increment
    this.increment_NCF_json = 'https://sisboa.net/SisBOA-WEB/Comprobantes_Fiscales/Increment_No/increment_NCF.php';

    // listado de clientes con rnc
    this.listado_clientes_RNC = 'https://sisboa.net/Api-Consultar-RNC/consulta_rnc.php?Cedula_Rnc=';
  }



  public GetAll_Comprobantes_Json() {
    return this.http.get(this.get_all_comprobantes_Json).toPromise();
  }
  public Get_Ultima_Secuencia_Json() {
    return this.http.get(this.get_ultima_secuencia_json).toPromise();
  }
  public Descargar_Listado_Rnc() {

      let linkConsulta:string =
       'https://webposonline.com/webposapis/api/cloud/CheckRnc?apiKey={5a585a13-b385-4686-90d3-71be94a9f69c}&rnc={101071559}';
      return this.http.get(linkConsulta).toPromise();
    //const browserLink = this.browser.create(this.descargar_listado_rnc, '_blank');   //_self
    // return this.http.get(this.descargar_listado_rnc).toPromise();
  }

  public Get_Cliente_Con_RNC_Json(cedulaRnc:string) {
    return this.http.get(this.listado_clientes_RNC+cedulaRnc).toPromise();
  }






         // UPDATES
  public Update_Consumidor(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("consumidorFinal_ParteFija", parteFija);
    formData.append("Desde_ConsumidorFinal", desde);
    formData.append("Hasta_ConsumidorFinal", hasta);
    formData.append("Aviso_ConsumidorFinal", aviso);

    return this.http.post(this.update_consumidor_json, formData).toPromise();
  }
  public Update_Credito_Fiscal(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("CreditoFiscal_ParteFija", parteFija);
    formData.append("Desde_CreditoFiscal", desde);
    formData.append("Hasta_CreditoFiscal", hasta);
    formData.append("Aviso_CreditoFiscal", aviso);

    return this.http.post(this.update_credito_fiscal_json, formData).toPromise();
  }
  public Update_Regimen_Especial(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("RegimenEspecial_ParteFija", parteFija);
    formData.append("Desde_RegimenEspecial", desde);
    formData.append("Hasta_RegimenEspecial", hasta);
    formData.append("Aviso_RegimenEspecial", aviso);

    return this.http.post(this.update_regimen_especial_json, formData).toPromise();
  }
  public Update_Gubernamental(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("Gubernamentales_ParteFija", parteFija);
    formData.append("Desde_Gubernamentales", desde);
    formData.append("Hasta_Gubernamentales", hasta);
    formData.append("Aviso_Gubernamentales", aviso);

    return this.http.post(this.update_gubernamental_json, formData).toPromise();
  }
  // menos comunes
  public Update_Nota_Credito(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("NotaCredito_ParteFija", parteFija);
    formData.append("Desde_NotaCredito", desde);
    formData.append("Hasta_NotaCredito", hasta);
    formData.append("Aviso_NotaCredito", aviso);

    return this.http.post(this.update_nota_credito_json, formData).toPromise();
  }
  public Update_Nota_Debito(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("NotaDebito_ParteFija", parteFija);
    formData.append("Desde_NotaDebito", desde);
    formData.append("Hasta_NotaDebito", hasta);
    formData.append("Aviso_NotaDebito", aviso);

    return this.http.post(this.update_nota_debito_json, formData).toPromise();
  }
  public Update_Proveedores_Informales(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("ProveedoresInformales_ParteFija", parteFija);
    formData.append("Desde_ProveedoresInformales", desde);
    formData.append("Hasta_ProveedoresInformales", hasta);
    formData.append("Aviso_ProveedoresInformales", aviso);

    return this.http.post(this.update_proveedores_informales_json, formData).toPromise();
  }
  public Update_Gastos_Menores(parteFija:string, desde:any, hasta:any, aviso:any) {
    const formData = new FormData();
    
    formData.append("GastosMenores_ParteFija", parteFija);
    formData.append("Desde_GastosMenores", desde);
    formData.append("Hasta_GastosMenores", hasta);
    formData.append("Aviso_GastosMenores", aviso);

    return this.http.post(this.update_gastos_menores_json, formData).toPromise();
  }




   // INCREMENT
  public Increment_NCF_Json(nombre_Comprobante:string) {
    const formData = new FormData();
    
    formData.append("nombre_Comprobante", nombre_Comprobante);
    return this.http.post(this.increment_NCF_json, formData).toPromise();
  }
  // GET ULTIMO
  public Get_Ultimo_NCF_Json(nombre_Comprobante:string) {


  return this.http.get(this.get_ultimo_ncf_json + '?nombre_Comprobante=' + nombre_Comprobante).toPromise();
}



// Guardar comprobantes en local storage
public Guardar_Comprobantes_En_Storage(){
        this.GetAll_Comprobantes_Json().then((data:any)=>{
            if (data['ComprobantesFiscales']) {    

            if (this.my_local_storage.Comprobantes_Fiscales_Storage) {
              localStorage.removeItem(this.my_local_storage.Comprobantes_Fiscales_Storage)
              localStorage.setItem(this.my_local_storage.Comprobantes_Fiscales_Storage, JSON.stringify(data['ComprobantesFiscales']));
            }else{
              localStorage.setItem(this.my_local_storage.Comprobantes_Fiscales_Storage, JSON.stringify(data['ComprobantesFiscales']));
            }
             
              console.log('comprobantes actualizados!')
            }
        });
}
public NCF_From_LocalStorage(){
  if (localStorage.getItem(this.my_local_storage.Comprobantes_Fiscales_Storage)) {
    //EXISTE
    let array_temporal_Storage = localStorage.getItem(this.my_local_storage.Comprobantes_Fiscales_Storage);
    let arrayTemp = JSON.parse(array_temporal_Storage);
    //console.log(arrayTemp[0].consumidorFinal_ParteFija);

    return arrayTemp[0]; 
  }
}


public autocompletar_PadLeft(pad, user_str, pad_pos)
{
  if (typeof user_str === 'undefined') {
    return pad;
  }
  if (pad_pos == 'l'){
     return (pad + user_str).slice(-pad.length);
     }
  else{
    return (user_str + pad).substring(0, pad.length);
    }
    
}






}
